import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, TextField } from "@mui/material";

import { CollectionItem } from "../classes";
import { formatMoney, keyCodeToNumberChangeArray } from "../utils";

export default function CustomPrice({ onChange, value, maxValue, disabled }) {
  const handleOnKeyDown = (e) => {
    const array = CollectionItem.numberToArray(value);

    keyCodeToNumberChangeArray(e, array);

    const newValue = CollectionItem.arrayToNumber(array);

    if (maxValue && newValue > maxValue) return;

    onChange(newValue);
  };

  const label = maxValue ? `Price (Max ${formatMoney(maxValue)})` : "Price";

  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Typography
        style={{
          marginRight: "0.25rem",
          color: disabled ? "rgba(0, 0, 0, 0.38)" : "inherit",
        }}
      >
        $
      </Typography>
      <TextField
        style={{ width: 170 }}
        size="small"
        variant="outlined"
        label={label}
        onKeyDown={handleOnKeyDown}
        value={formatMoney(value, true)}
        disabled={disabled}
        inputProps={{
          pattern: "[0-9]+([.,][0-9]+)?",
          inputMode: "numeric",
        }}
      />
    </Grid>
  );
}

CustomPrice.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number,
  disabled: PropTypes.bool,
};
