import React, { useState, useRef, useEffect } from "react";
import format from "date-fns/format";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  TextField,
  Divider,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import BuildIcon from "@mui/icons-material/Build";
import LockIcon from "@mui/icons-material/Lock";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import CreateIcon from "@mui/icons-material/Create";

import { Resident } from "../classes/resident";
import { Collection } from "../classes/collection";
import { CollectionItem } from "../classes/collection-item";
import { CollectionPayment } from "../classes/collection-payment";

import { useMe } from "../store/me";

import { formatMoney } from "../utils";
import { useCollectionsApi } from "../apis";
import CollectionItemRow from "./CollectionItemRow";
import CollectionPaymentRow from "./CollectionPaymentRow";

interface CollectionModalProps {
  active: boolean;
  closeModal: () => void;
  collection: Collection;
  collections: { items: Collection[]; total: number; overallBalance: number };
  resident: Resident;
  onCallback: () => void;
}

export const CollectionModal = ({
  active,
  closeModal,
  collection: _collection,
  collections,
  resident,
  onCallback,
}: CollectionModalProps) => {
  const { isAdmin } = useMe();
  const { addCollection, updateCollection, deleteCollection } =
    useCollectionsApi();

  const addCollectionEl = useRef<HTMLButtonElement>(null);

  const [collection, setCollection] = useState(_collection);

  useEffect(() => {
    setCollection(_collection);
  }, [_collection]);

  // NOTES
  const setNotes: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const newCollection = new Collection(collection);
    newCollection.notes = e.target.value;
    setCollection(newCollection);
  };

  const setRejectedNotes: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const newCollection = new Collection(collection);
    newCollection.rejectedNotes = e.target.value;
    setCollection(newCollection);
  };

  const addCollectionItem = () => {
    const newCollection = new Collection(collection);
    const collectionItem = new CollectionItem();
    newCollection.addItem(collectionItem);
    setCollection(newCollection);
  };

  const addPayment = () => {
    const newCollection = new Collection(collection);
    newCollection.addPayment(new CollectionPayment());
    setCollection(newCollection);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    // Ignore when the collection amount is empty
    if (collection.total() === 0 && collection.amountPaid() === 0) {
      closeModal();
      return;
    }

    const newCollection = new Collection(collection);
    newCollection.pending();

    if (collection.isPersisted()) {
      await updateCollection(newCollection.id, newCollection.prepareForApi());
    } else {
      await addCollection(newCollection.prepareForApi());
    }

    closeModal();

    onCallback();
  };

  const handleReject = async () => {
    const newCollection = new Collection(collection);
    newCollection.reject();

    await updateCollection(newCollection.id, newCollection.prepareForApi());

    setCollection(newCollection);

    closeModal();

    onCallback();
  };

  const handleUpdateNotes = async () => {
    await updateCollection(collection.id, collection.prepareForApi());

    closeModal();

    onCallback();
  };

  const handleDelete = async () => {
    if (isAdmin()) {
      await handleDeleteAdmin();
    } else {
      const newCollection = new Collection(collection);
      newCollection.pendingDelete();

      await updateCollection(newCollection.id, newCollection.prepareForApi());
      setCollection(newCollection);
      closeModal();

      onCallback();
    }
  };

  const handleApprove = async () => {
    const newCollection = new Collection(collection);
    newCollection.approve();

    await updateCollection(newCollection.id, newCollection.prepareForApi());
    setCollection(newCollection);
    closeModal();

    onCallback();
  };

  const handleDeleteAdmin = async () => {
    if (window.confirm("Are you sure you want to delete this collection?")) {
      await deleteCollection(collection.id);
      closeModal();

      onCallback();
    }
  };

  const isPendingOrApproved =
    collection.isApproved() ||
    collection.isPending() ||
    collection.isPendingDelete();

  return (
    <Dialog open={active} onClose={closeModal} fullWidth>
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            {resident.lastName}, {resident.firstName}
            <Typography>Pay Schedule: {resident.paySchedule}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={8}>
                <Typography style={{ textAlign: "right" }}>Total:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography style={{ textAlign: "right" }}>
                  {formatMoney(collection.total())}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={8}>
                <Typography style={{ textAlign: "right" }}>
                  Amount Paid:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography style={{ textAlign: "right" }}>
                  {formatMoney(collection.amountPaid())}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={8}>
                <Typography style={{ textAlign: "right" }}>
                  Amount Due:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography style={{ textAlign: "right" }}>
                  {formatMoney(collection.amountDue())}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          minHeight: "50vh",
        }}
      >
        <form onSubmit={handleSubmit}>
          {collection.items &&
            collection.items.map((item, index) => (
              <CollectionItemRow
                key={index}
                index={index}
                item={item}
                resident={resident}
                collection={collection}
                collections={collections}
                setCollection={setCollection}
                disabled={isPendingOrApproved}
              />
            ))}

          {!isPendingOrApproved && (
            <Button
              color="primary"
              onClick={addCollectionItem}
              startIcon={<AddCircleIcon />}
              style={{ marginTop: "0.5rem" }}
              fullWidth
            >
              Add Line Item
            </Button>
          )}

          <Divider style={{ margin: "0.5rem 0" }} />

          {collection.payments &&
            collection.payments.map((payment, index) => (
              <CollectionPaymentRow
                key={index}
                index={index}
                resident={resident}
                payment={payment}
                collection={collection}
                collections={collections}
                setCollection={setCollection}
                disabled={isPendingOrApproved}
              />
            ))}

          {!isPendingOrApproved && (
            <Button
              color="primary"
              onClick={addPayment}
              startIcon={<AddCircleIcon />}
              style={{ marginTop: "0.5rem" }}
              fullWidth
            >
              Add Payment
            </Button>
          )}

          <Divider style={{ margin: "0.5rem 0" }} />

          <TextField
            variant="outlined"
            fullWidth
            style={{ margin: "0.5rem 0" }}
            label="Notes"
            value={collection.notes}
            onChange={setNotes}
            disabled={isPendingOrApproved && !collection.isPendingDelete()}
            multiline
            rows={2}
          />

          {((collection.isPending() && isAdmin) || collection.isRejected()) && (
            <TextField
              variant="outlined"
              fullWidth
              style={{ margin: "0.5rem 0" }}
              label="Rejected Notes"
              value={collection.rejectedNotes}
              onChange={setRejectedNotes}
              disabled={!collection.isPending() || !isAdmin}
              multiline
              rows={2}
            />
          )}

          {collection.createdAt && (
            <Typography>
              Created: {format(new Date(collection.createdAt), "PPp")}
            </Typography>
          )}

          {collection.isRejected() && (
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  style={{ marginTop: "2rem" }}
                  color="secondary"
                  onClick={handleDelete}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          )}

          {/* Used as a "submit" proxy for the DialogAction buttons */}
          <button
            ref={addCollectionEl}
            type="submit"
            style={{ display: "none" }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        {!collection.isPersisted() && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => addCollectionEl.current?.click()}
          >
            Add Collection
          </Button>
        )}

        {collection.isPersisted() && collection.isApproved() && !isAdmin && (
          <Button variant="contained" startIcon={<LockIcon />} disabled>
            Approved
          </Button>
        )}

        {collection.isPersisted() && collection.isApproved() && isAdmin && (
          <Button
            color="primary"
            variant="contained"
            onClick={handleReject}
            startIcon={<ThumbDownIcon />}
          >
            Unapprove
          </Button>
        )}

        {collection.isPersisted() && collection.isPending() && !isAdmin && (
          <Button variant="contained" startIcon={<LockIcon />} disabled>
            Submitted
          </Button>
        )}

        {collection.isPersisted() && collection.isPending() && isAdmin && (
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleReject}
              >
                Reject
              </Button>
            </Grid>

            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleApprove}
              >
                Approve
              </Button>
            </Grid>
          </Grid>
        )}

        {collection.isPersisted() && collection.isRejected() && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<BuildIcon />}
            onClick={() => addCollectionEl.current?.click()}
          >
            Resubmit
          </Button>
        )}

        {collection.isPersisted() && collection.isPendingDelete() && !isAdmin && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<CreateIcon />}
            onClick={handleUpdateNotes}
          >
            Update Notes
          </Button>
        )}

        {collection.isPersisted() && collection.isPendingDelete() && isAdmin && (
          <>
            <Button color="primary" variant="contained" onClick={handleReject}>
              Reject
            </Button>

            <Button
              color="secondary"
              variant="contained"
              onClick={handleDeleteAdmin}
            >
              Delete
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
