import React from "react";
import styled from "styled-components";
import { Grid, Typography } from "@mui/material";
import {
  RadioButton,
  useTableFilter,
  TableRow,
  TableBody,
  TableHeader,
  TableSelectedSectionButton,
  TableCell,
  TableSearchField,
  LocationFilter,
  ResidentFilter,
  TableFilterModal,
} from "./index";

import {
  Resident,
  PENDING,
  REQUEST_MOVE_IN,
  MOVED_IN,
  REQUEST_MOVE_OUT,
  MOVED_OUT,
} from "../classes/resident";
import { Location } from "../classes/location";
import { convertUTCToLocal } from "../utils/date-helpers";

import { useNavigate } from "react-router-dom";
import format from "date-fns/format";
import { colors } from "../constants";
import {
  faSignInAlt,
  faHome,
  faPaperPlane,
  faSignOutAlt,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

const Header = () => (
  <>
    <TableCell basis="200px">Name</TableCell>
    <TableCell basis="66px">Location</TableCell>
    <TableCell basis="120px">Supervision</TableCell>
    <TableCell basis="86px">Rent Due</TableCell>
  </>
);

const EmptyView = () => (
  <Typography
    variant="h5"
    align="center"
    sx={{
      color: colors.grey[5],
      marginTop: 8,
    }}
  >
    No Residents
  </Typography>
);

interface RowProps {
  row: {
    resident?: Resident;
    location?: Location;
  };
}

const Row = ({ row, ...props }: RowProps) => (
  <TableRow row={row} SelectedSectionElem={SelectedSection} {...props}>
    <TableCell basis="200px" semiBold>
      {row.resident && `${row.resident.lastName}, ${row.resident.firstName}`}
    </TableCell>
    <TableCell basis="66px" color={colors.grey[3]}>
      {row.location && row.location.name}
    </TableCell>
    <TableCell basis="120px" color={colors.grey[3]}>
      {row.resident && row.resident.supervision}
    </TableCell>
    <TableCell basis="86px" color={colors.grey[3]}>
      {row.resident &&
        row.resident.rentDueDate &&
        format(convertUTCToLocal(row.resident.rentDueDate), "M/dd/yyyy")}
    </TableCell>
  </TableRow>
);

interface SelectedSectionProps {
  row: RecordProps;
}

const SelectedSection = ({ row }: SelectedSectionProps) => {
  const navigate = useNavigate();

  const goTo = (path: string) => () => navigate(`${path}/${row.resident.id}`);

  return (
    <>
      {row.resident && row.resident.status === PENDING && (
        <>
          <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
            View
          </TableSelectedSectionButton>
          <TableSelectedSectionButton
            icon={faSignInAlt}
            onClick={goTo("request-move-in")}
          >
            Req Move In
          </TableSelectedSectionButton>
        </>
      )}

      {row.resident && row.resident.status === REQUEST_MOVE_IN && (
        <>
          <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
            View
          </TableSelectedSectionButton>
          <TableSelectedSectionButton
            icon={faSignInAlt}
            onClick={goTo("move-in")}
          >
            Move In
          </TableSelectedSectionButton>
        </>
      )}

      {row.resident && row.resident.status === MOVED_IN && (
        <>
          <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
            View
          </TableSelectedSectionButton>
          <TableSelectedSectionButton
            icon={faHome}
            onClick={goTo("location-change")}
          >
            Loc Change
          </TableSelectedSectionButton>

          <TableSelectedSectionButton
            icon={faPaperPlane}
            onClick={goTo("request-move-out")}
          >
            Req Move Out
          </TableSelectedSectionButton>

          <TableSelectedSectionButton
            icon={faSignOutAlt}
            onClick={goTo("move-out")}
          >
            Move Out
          </TableSelectedSectionButton>
        </>
      )}

      {row.resident && row.resident.status === REQUEST_MOVE_OUT && (
        <>
          <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
            View
          </TableSelectedSectionButton>
          <TableSelectedSectionButton
            icon={faSignOutAlt}
            onClick={goTo("move-out")}
          >
            Move Out
          </TableSelectedSectionButton>
        </>
      )}

      {row.resident && row.resident.status === MOVED_OUT && (
        <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
          View
        </TableSelectedSectionButton>
      )}
    </>
  );
};

interface StatusFilterProps {
  setPage: React.Dispatch<React.SetStateAction<number>>;
}
const StatusFilter = ({ setPage }: StatusFilterProps) => {
  const { status: value, setStatus } = useTableFilter();

  const _setStatus = (status: string) => {
    setStatus(status);
    setPage(0);
  };

  return (
    <div style={{ backgroundColor: colors.grey[6] }}>
      <Typography sx={{ marginLeft: 0.5, fontWeight: "bold" }}>
        Group Residents by:
      </Typography>
      <Grid
        container
        wrap="nowrap"
        sx={{ paddingX: 0.5, paddingY: 0.5, gap: "8px" }}
      >
        <RadioButton
          active={value === PENDING}
          value={PENDING}
          onClick={_setStatus}
        >
          Pend
        </RadioButton>
        <RadioButton
          active={value === REQUEST_MOVE_IN}
          value={REQUEST_MOVE_IN}
          onClick={_setStatus}
        >
          Rq Move In
        </RadioButton>
        <RadioButton
          active={value === MOVED_IN}
          value={MOVED_IN}
          onClick={_setStatus}
        >
          Moved In
        </RadioButton>
        <RadioButton
          active={value === REQUEST_MOVE_OUT}
          value={REQUEST_MOVE_OUT}
          onClick={_setStatus}
        >
          Rq Move Out
        </RadioButton>
        <RadioButton
          active={value === MOVED_OUT}
          value={MOVED_OUT}
          onClick={_setStatus}
        >
          Move Out
        </RadioButton>
      </Grid>
    </div>
  );
};

//
// 64px - Header
// 52px - Quick search
// 54px - Group by Resident
const ResidentTableStyles = styled.div`
  height: calc(100% - 64px - 52px - 54px);
`;

interface RecordProps {
  resident: Resident;
  location?: Location;
}

interface ResidentTableProps {
  rows: RecordProps[];
  total: number;
  page: number;
  take: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const ResidentTable = ({
  rows,
  total,
  page,
  take,
  setPage,
  search,
  setSearch,
}: ResidentTableProps) => {
  const filterContent = [ResidentFilter, LocationFilter];

  return (
    <ResidentTableStyles>
      <TableFilterModal filterContent={filterContent} />
      <TableSearchField
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        filterContent={filterContent}
      />
      <StatusFilter setPage={setPage} />
      <TableHeader>
        <Header />
      </TableHeader>
      <TableBody
        rows={rows}
        total={total}
        page={page}
        take={take}
        setPage={setPage}
        RowElem={Row}
        EmptyViewElem={EmptyView}
      />
    </ResidentTableStyles>
  );
};

export default ResidentTable;
