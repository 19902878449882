import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton, Button, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import styled from "styled-components";
import { colors } from "../constants";

const FormHeaderStyles = styled(Grid)`
  position: sticky !important;
  top: 0;
  z-index: 2;
  background-color: ${colors.white} !important;
  border-bottom: 1px solid ${colors.grey[0]};
`;

interface PrimaryButtonProps {
  text: string;
  onClick: () => void;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  disabled?: boolean;
}

type FormHeaderProps = {
  title: string;
  primaryButton?: PrimaryButtonProps;
};

const FormHeader = ({ title, primaryButton }: FormHeaderProps) => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <FormHeaderStyles
      container
      wrap="nowrap"
      justifyContent="space-between"
      alignItems="center"
      height="4rem"
    >
      <Grid item>
        <IconButton onClick={goBack}>
          <ChevronLeftIcon />
        </IconButton>
      </Grid>

      <Grid item>
        <Typography variant="h6">{title}</Typography>
      </Grid>

      <Grid item>
        <Grid container spacing={1} paddingRight={1} wrap="nowrap">
          {primaryButton && (
            <Grid item>
              <Button color="primary" variant="outlined" {...primaryButton}>
                {primaryButton.text}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormHeaderStyles>
  );
};

FormHeader.propTypes = {
  title: PropTypes.string.isRequired,
  primaryButton: PropTypes.object,
};

export default FormHeader;
