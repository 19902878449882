import { useCallback } from "react";
import { useApi, GetListInputType, GetListOutputType } from "../hooks";
import { Location } from "../classes/location";

export function useLocationsApi() {
  const { fetchGETList, fetchGET, fetchPOST, fetchPUT, fetchDELETE } = useApi();

  const getLocation = useCallback(
    (id) => fetchGET(`locations/${id}`),
    [fetchGET]
  );

  const getLocations = useCallback(
    (queryParams?) => fetchGETList("locations", queryParams),
    [fetchGETList]
  );

  const addLocation = useCallback(
    (data) => fetchPOST("locations", data),
    [fetchPOST]
  );

  const updateLocation = useCallback(
    (id, data) => fetchPUT(`locations/${id}`, data),
    [fetchPUT]
  );

  const deleteLocation = useCallback(
    (id) => fetchDELETE(`locations/${id}`),
    [fetchDELETE]
  );

  return {
    getLocation,
    getLocations,
    addLocation,
    updateLocation,
    deleteLocation,
  };
}

export interface GetLocationsInputType extends GetListInputType {
  search?: string;
}

export type GetLocationsOutputType = GetListOutputType<Location>;
