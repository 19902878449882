import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ResidentsRoutes from "./Residents";
import LocationRoutes from "./Locations";
import ManagersRoutes from "./Managers";
import ActivityRoutes from "./Activity";
// import Test from "./Test";
import CollectionRoutes from "./Collections";

import SideMenuLayout from "../../components/SideMenuLayout";

import { useMe } from "../../store";
import { TableFilter } from "../../components/TableFilter";

export function DashboardRoutes() {
  const { isAdmin } = useMe();
  return (
    <TableFilter>
      <SideMenuLayout>
        <Routes>
          <Route path="/residents/*" element={<ResidentsRoutes />} />
          <Route path="/activity/*" element={<ActivityRoutes />} />
          <Route path="/collections/*" element={<CollectionRoutes />} />

          {isAdmin() && (
            <Route path="/locations/*" element={<LocationRoutes />} />
          )}

          {isAdmin() && (
            <Route path="/managers/*" element={<ManagersRoutes />} />
          )}

          {/* <Route path="/test"> */}
          {/*   <span>hey</span> */}
          {/*   <Test /> */}
          {/* </Route> */}

          <Route path="*" element={<Navigate to="residents" />} />
        </Routes>
      </SideMenuLayout>
    </TableFilter>
  );
}
