import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import { Location } from "./location";
import { Photo } from "./photo";
import { formatMoney } from "../utils";

// TODO: Move this under a single const.
export const PENDING = "PENDING";
export const REQUEST_MOVE_IN = "REQUEST_MOVE_IN";
export const MOVED_IN = "MOVED_IN";
export const REQUEST_MOVE_OUT = "REQUEST_MOVE_OUT";
export const MOVED_OUT = "MOVED_OUT";
export const VIEW = "VIEW";
export const LOCATION_CHANGE = "LOCATION_CHANGE";

export const PAYMENT_SCHEDULE = {
  SCHEDULE_1x15: "1x15",
  SCHEDULE_5x20: "5x20",
};

export const PAYMENT_SCHEDULE_OPTIONS = [
  { text: "1x15", value: PAYMENT_SCHEDULE.SCHEDULE_1x15 },
  { text: "5x20", value: PAYMENT_SCHEDULE.SCHEDULE_5x20 },
];

export const RESIDENT_STATUS_OPTIONS = [
  { text: "Pending", value: PENDING },
  { text: "Request Move In", value: REQUEST_MOVE_IN },
  { text: "Moved In", value: MOVED_IN },
  { text: "Request Move Out", value: REQUEST_MOVE_OUT },
  { text: "Moved Out", value: MOVED_OUT },
];

export interface ResidentConstructorType extends DatabaseObjectConstructorType {
  firstName?: string;
  lastName?: string;
  status?: string;
  supervision?: string;
  contactNumber?: string;
  location?: Location;
  locationId?: number;
  hasBPA?: boolean;
  bpaPending?: boolean;
  bpaEndDate?: Date | string | null;
  isGeo?: boolean;
  totalCollected?: number;
  moveInDate?: Date | string | null;
  contactToSchedule?: boolean;
  notes?: string;
  moveOutNotes?: string;
  moveOutDate?: Date | string | null;
  hasUa?: boolean;
  uaPhotos?: Photo[];
  rentDueDate?: Date | string | null;
  paySchedule?: string;
  paymentContract?: string;
  noPaymentContract?: boolean;
  alreadyBeenSwitched?: boolean;
  cannotPayLate?: boolean;
  improperPayment?: boolean;
  overallBalance?: number;
}

export class Resident extends DatabaseObject {
  firstName?: string = "";
  lastName?: string = "";
  status?: string = "";
  supervision?: string = "";
  contactNumber?: string = "";
  location?: Location;
  locationId?: number;
  hasBPA = false;
  bpaPending = false;
  bpaEndDate?: Date | null = null;
  isGeo = false;
  totalCollected = 0;
  moveInDate?: Date | null = null;
  contactToSchedule = false;
  notes?: string = "";
  moveOutNotes?: string = "";
  moveOutDate?: Date | null = null;
  hasUa = false;
  uaPhotos?: Photo[];
  rentDueDate?: Date | null = null;
  paySchedule?: string = "";
  paymentContract?: string = "";
  noPaymentContract = false;
  alreadyBeenSwitched = false;
  cannotPayLate = false;
  improperPayment = false;
  overallBalance = 0;

  constructor(data?: ResidentConstructorType) {
    super(data);

    if (!data) return;

    if (data.firstName) this.firstName = data.firstName;
    if (data.lastName) this.lastName = data.lastName;
    if (data.status) this.status = data.status;
    if (data.supervision) this.supervision = data.supervision;
    if (data.contactNumber) this.contactNumber = data.contactNumber;
    if (data.location) this.location = new Location(data.location);
    if (data.locationId) this.locationId = data.locationId;
    if (data.hasBPA) this.hasBPA = data.hasBPA;
    if (data.bpaPending) this.bpaPending = data.bpaPending;
    if (data.bpaEndDate) this.bpaEndDate = new Date(data.bpaEndDate);
    if (data.isGeo) this.isGeo = data.isGeo;
    if (data.totalCollected) this.totalCollected = data.totalCollected;
    if (data.moveInDate) this.moveInDate = new Date(data.moveInDate);
    if (data.contactToSchedule) this.contactToSchedule = data.contactToSchedule;
    if (data.notes) this.notes = data.notes;
    if (data.moveOutNotes) this.moveOutNotes = data.moveOutNotes;
    if (data.moveOutDate) this.moveOutDate = new Date(data.moveOutDate);
    if (data.hasUa) this.hasUa = data.hasUa;
    if (data.uaPhotos) this.uaPhotos = data.uaPhotos;
    if (data.rentDueDate) this.rentDueDate = new Date(data.rentDueDate);
    if (data.paySchedule) this.paySchedule = data.paySchedule;
    if (data.paymentContract) this.paymentContract = data.paymentContract;
    if (data.noPaymentContract) this.noPaymentContract = data.noPaymentContract;
    if (data.alreadyBeenSwitched)
      this.alreadyBeenSwitched = data.alreadyBeenSwitched;
    if (data.cannotPayLate) this.cannotPayLate = data.cannotPayLate;
    if (data.improperPayment) this.improperPayment = data.improperPayment;
    if (data.overallBalance) this.overallBalance = data.overallBalance;
  }

  clone() {
    return new Resident(this);
  }

  toString() {
    return (
      `Resident` +
      `${super.toString()}` +
      `First Name:            ${this.firstName}\n` +
      `Last Name:             ${this.lastName}\n` +
      `Status:                ${this.status}\n` +
      `Supervision:           ${this.supervision}\n` +
      `Contact Number:        ${this.contactNumber}\n` +
      `${this.location}` +
      `Has BPA:               ${this.hasBPA}\n` +
      `BPA Pending:           ${this.bpaPending}\n` +
      `BPA End Date:          ${
        this.bpaEndDate
          ? formatInTimeZone(this.bpaEndDate, "M/dd/yyyy", "UTC")
          : ""
      }\n` +
      `Is GEO                 ${this.isGeo}` +
      `Total Collected        ${this.totalCollected}` +
      `Move-In Date:          ${
        this.moveInDate
          ? formatInTimeZone(this.moveInDate, "M/dd/yyyy", "UTC")
          : ""
      }\n` +
      `Contact To Schedule:   ${this.contactToSchedule}\n` +
      `Notes:                 ${this.notes}\n` +
      `Move-Out Notes:        ${this.moveOutNotes}\n` +
      `Move-Out Date:         ${
        this.moveOutDate
          ? formatInTimeZone(this.moveOutDate, "M/dd/yyyy", "UTC")
          : ""
      }\n` +
      `Has UA:                ${this.hasUa}\n` +
      `UA Photos:             ${this.uaPhotos && this.uaPhotos.length}\n` +
      `Rent Due Date:         ${
        this.rentDueDate
          ? formatInTimeZone(this.rentDueDate, "M/dd/yyyy", "UTC")
          : ""
      }\n` +
      `Pay Schedule:          ${this.paySchedule}\n` +
      `Payment Contract:      ${this.paymentContract}\n` +
      `No Payment Contract:   ${this.noPaymentContract}\n` +
      `Already Been Switched: ${this.alreadyBeenSwitched}\n` +
      `Cannot Pay Late:       ${this.cannotPayLate}\n` +
      `Improper Payment:      ${this.improperPayment}\n` +
      `Overall Balance:       ${formatMoney(this.overallBalance)}\n`
    );
  }

  isPending() {
    return this.status === PENDING;
  }

  isRequestMoveIn() {
    return this.status === REQUEST_MOVE_IN;
  }
}
