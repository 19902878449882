import React from "react";
import ResidentsFormPage from "../../../components/ResidentsFormPage";
import { useParams } from "react-router-dom";
import { Resident } from "../../../classes";
import { useResidentsApi } from "../../../apis";

export default function RequestMoveIn() {
  const { id: residentId } = useParams();
  const { addResident, updateResident } = useResidentsApi();

  const onSave = async (resident: Resident) => {
    if (residentId) {
      return updateResident(residentId, resident.prepareForApi());
    } else {
      return addResident(resident.prepareForApi());
    }
  };

  return (
    <ResidentsFormPage
      title="Req Move-in"
      status="REQUEST_MOVE_IN"
      residentId={Number(residentId)}
      onSave={onSave}
      saveButtonText="Req Move In"
    />
  );
}
