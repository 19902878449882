import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "@mui/material";
import {
  TableRow,
  TableBody,
  TableHeader,
  TableSelectedSectionButton,
  TableCell,
  TableSearchField,
} from "./index";

import { useNavigate } from "react-router-dom";
import { colors } from "../constants";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import { User } from "../classes/user";

const Header = () => (
  <>
    <TableCell basis="240px">Name</TableCell>
    <TableCell basis="80px">Disabled</TableCell>
    <TableCell basis="240px">Email</TableCell>
    <TableCell basis="80px">Admin</TableCell>
  </>
);

const EmptyView = () => (
  <Typography
    variant="h5"
    align="center"
    sx={{
      color: colors.grey[5],
      marginTop: 8,
    }}
  >
    No Managers
  </Typography>
);

interface RowProps {
  row: {
    user?: User;
  };
}

const Row = ({ row, ...props }: RowProps) => (
  <TableRow row={row} SelectedSectionElem={SelectedSection} {...props}>
    <TableCell basis="240px" semiBold>
      {row.user && row.user.lastName} {row.user && row.user.firstName}
    </TableCell>
    <TableCell basis="80px" color={colors.grey[3]}>
      {row.user && row.user.disabled ? "DISABLED" : ""}
    </TableCell>
    <TableCell basis="240px" color={colors.grey[3]}>
      {row.user && row.user.email}
    </TableCell>
    <TableCell basis="80px" color={colors.grey[3]}>
      {row.user && row.user.roles.join(", ")}
    </TableCell>
  </TableRow>
);

Row.propTypes = {
  row: PropTypes.object.isRequired,
};

interface SelectedSectionProps {
  row: RecordProps;
}

const SelectedSection = ({ row }: SelectedSectionProps) => {
  const navigate = useNavigate();

  const goTo = (path: string) => () => navigate(`${path}/${row.user.id}`);

  return (
    <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
      View
    </TableSelectedSectionButton>
  );
};

SelectedSection.propTypes = {
  row: PropTypes.object.isRequired,
};

const ManagerTableStyles = styled.div`
  height: calc(100% - 64px - 52px);
`;

interface RecordProps {
  user: User;
}

interface ManagerTableProps {
  rows: RecordProps[];
  total: number;
  page: number;
  take: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const ManagerTable = ({
  rows,
  total,
  page,
  take,
  setPage,
  search,
  setSearch,
}: ManagerTableProps) => {
  return (
    <ManagerTableStyles>
      <TableSearchField
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <TableHeader>
        <Header />
      </TableHeader>
      <TableBody
        rows={rows}
        total={total}
        page={page}
        take={take}
        setPage={setPage}
        RowElem={Row}
        EmptyViewElem={EmptyView}
      />
    </ManagerTableStyles>
  );
};

export default ManagerTable;
