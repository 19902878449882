import { useCallback } from "react";
import { useApi } from "../hooks";

export function usePhotosApi() {
  const { fetchDELETE } = useApi();

  const deletePhoto = useCallback(
    (id) => fetchDELETE(`photos/${id}`),
    [fetchDELETE]
  );

  return {
    deletePhoto,
  };
}
