export default function keyCodeToNumberChangeArray(
  e: KeyboardEvent,
  array: string[]
): void {
  // Is a number 0 - 9 or Backspace
  switch (e.code) {
    // 0
    case "Digit0":
      array.push("0");
      break;

    // 1
    case "Digit1":
      array.push("1");
      break;

    // 2
    case "Digit2":
      array.push("2");
      break;

    // 3
    case "Digit3":
      array.push("3");
      break;

    // 4
    case "Digit4":
      array.push("4");
      break;

    // 5
    case "Digit5":
      array.push("5");
      break;

    // 6
    case "Digit6":
      array.push("6");
      break;

    // 7
    case "Digit7":
      array.push("7");
      break;

    // 8
    case "Digit8":
      array.push("8");
      break;

    // 9
    case "Digit9":
      array.push("9");
      break;

    // backspace
    case "Backspace":
      array.pop();
      break;

    // ignore
    default:
      return;
  }
}
