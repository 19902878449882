import React, { useState } from "react";
import ManagerForm from "../../../components/ManagerForm";
import { useUsersApi } from "../../../apis";
import { useNavigate } from "react-router-dom";
import { User } from "../../../classes";

export default function AddManager() {
  const { addUser } = useUsersApi();
  const navigate = useNavigate();
  const [manager, setManager] = useState(
    new User({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      roles: [],
      disabled: false,
    })
  );

  const onSave = async () => {
    try {
      await addUser(manager);

      alert(
        `An email with their password has been sent to ${manager.firstName} ${manager.lastName}. `
      );

      // Go back to whichever page they got there from.
      navigate(-1);
    } catch (e) {
      if (e instanceof Error) {
        alert(e.message);
      }
    }
  };

  return (
    <ManagerForm
      title="Add Manager"
      manager={manager}
      setManager={setManager}
      onSave={onSave}
    />
  );
}
