import { useCallback } from "react";
import { useApi, GetListInputType, GetListOutputType } from "../hooks";
import { Activity } from "../classes/activity";

export function useActivitiesApi() {
  const { fetchGETList, fetchGET, fetchPOST, fetchPUT, fetchDELETE } = useApi();

  const getActivity = useCallback(
    (id) => fetchGET(`activities/${id}`),
    [fetchGET]
  );

  const getActivities = useCallback(
    (queryParams) => fetchGETList("activities", queryParams),
    [fetchGETList]
  );

  const addActivity = useCallback(
    (data) => fetchPOST("activities", data),
    [fetchPOST]
  );

  const updateActivity = useCallback(
    (id, data) => fetchPUT(`activities/${id}`, data),
    [fetchPUT]
  );

  const deleteActivity = useCallback(
    (id) => fetchDELETE(`activities/${id}`),
    [fetchDELETE]
  );

  return {
    getActivity,
    getActivities,
    addActivity,
    updateActivity,
    deleteActivity,
  };
}

export interface GetActivitiesInputType extends GetListInputType {
  search?: string;
  activityTypes?: string[] | string;
  locations?: number | number[];
}

export type GetActivitiesOutputType = GetListOutputType<Activity>;
