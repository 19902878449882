import React, { useState, useCallback, useEffect } from "react";
import subDays from "date-fns/subDays";
import format from "date-fns/format";

import {
  PaymentContractModal,
  CollectionModal,
  RentDueTable,
  useTableFilter,
} from "../../../components";
import {
  useCollectionsApi,
  GetCollectionsOutputType,
} from "../../../apis/collections";
import {
  useResidentsApi,
  GetResidentsInputType,
  GetResidentsOutputType,
} from "../../../apis/residents";
import {
  Resident,
  REQUEST_MOVE_IN,
  MOVED_IN,
  REQUEST_MOVE_OUT,
} from "../../../classes/resident";
import { Location } from "../../../classes/location";
import { Collection } from "../../../classes/collection";

const CollectionOverdue = () => {
  const { getResidents } = useResidentsApi();
  const { getCollections } = useCollectionsApi();

  const { locations } = useTableFilter();
  const [search, setSearch] = useState("");

  const [residents, setResidents] = useState<GetResidentsOutputType>({
    items: [],
    total: 0,
  });

  // For Collection Modal
  const [collectionModalActive, setCollectionModalActive] = useState(false);
  const [resident, setResident] = useState(new Resident());
  const [collection, setCollection] = useState(new Collection());
  const [collections, setCollections] = useState<GetCollectionsOutputType>({
    items: [],
    total: 0,
    overallBalance: 0,
  });

  // For Payment Contract Modal
  const [paymentContractModalActive, setPaymentContractModalActive] =
    useState(false);

  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);

  const fetchData = useCallback(async () => {
    const customQuery: GetResidentsInputType = {
      skip: page * take,
      take,
      statuses: [REQUEST_MOVE_IN, MOVED_IN, REQUEST_MOVE_OUT],
    };

    if (locations.length > 0) {
      customQuery.locations = locations;
    }

    // When searching by text, we want to search ALL Residents,
    // and ignore what day it's on.
    if (search) {
      customQuery.search = search;
    } else {
      customQuery.rentDueDate__lt = format(
        subDays(new Date(), 1),
        "yyyy-MM-dd"
      );
    }

    const _residents = await getResidents(customQuery);
    setResidents(_residents);
  }, [page, take, locations, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const rows = residents.items.map((resident) => ({
    resident: new Resident(resident),
    location: new Location(resident.location),
  }));

  const handleAddCollection = async (
    resident: Resident,
    collection: Collection
  ) => {
    setResident(resident);
    setCollection(collection);

    // Fetch all of the resident's collections
    // because we need it for "Overall Balance"
    const _collections = await getCollections({
      residentId: resident.id,
      take: 0,
    });

    setCollections(_collections);

    setCollectionModalActive(true);
  };

  const handlePaymentContract = (resident: Resident) => {
    setResident(resident);
    setPaymentContractModalActive(true);
  };

  return (
    <>
      <RentDueTable
        rows={rows}
        search={search}
        setSearch={setSearch}
        hasDateFinder
        total={residents.total}
        page={page}
        take={take}
        setPage={setPage}
        onAddCollection={handleAddCollection}
        onPaymentContract={handlePaymentContract}
      />

      <CollectionModal
        active={collectionModalActive}
        closeModal={() => setCollectionModalActive(false)}
        collection={collection}
        collections={collections}
        resident={resident}
        onCallback={fetchData}
      />

      <PaymentContractModal
        active={paymentContractModalActive}
        closeModal={() => setPaymentContractModalActive(false)}
        resident={resident}
        onCallback={fetchData}
      />
    </>
  );
};

export default CollectionOverdue;
