import { getCollection } from "../utils";
import { Location } from "../../classes/location";

export const {
  getCollection: getLocations,
  addDocument: addLocation,
  editDocument: editLocation,
  removeDocument: removeLocation,
  reducer: LocationsReducer,
} = getCollection("locations", Location);
