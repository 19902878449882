import { getCollection } from "../utils";
import { Resident } from "../../classes/resident";

export const {
  getCollection: getResidents,
  getDocument: getResident,
  addDocument: addResident,
  editDocument: editResident,
  removeDocument: removeResident,
  reducer: ResidentsReducer,
} = getCollection("residents", Resident);
