import React from "react";
import ResidentsFormPage from "../../../components/ResidentsFormPage";
import { useParams } from "react-router-dom";
import { Resident } from "../../../classes";

import { useResidentsApi } from "../../../apis";

export default function LocationChange() {
  const { id: residentId } = useParams();
  const { updateResident } = useResidentsApi();

  const onSave = async (resident: Resident) =>
    updateResident(residentId, resident.prepareForApi());

  return (
    <ResidentsFormPage
      title="Location Change"
      status="LOCATION_CHANGE"
      residentId={Number(residentId)}
      onSave={onSave}
      saveButtonText="Save"
    />
  );
}
