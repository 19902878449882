import React, { useState, useEffect, useCallback } from "react";
import {
  useUsersApi,
  GetUsersInputType,
  GetUsersOutputType,
} from "../../../apis/users";

import { HeaderLink, ManagerTable } from "../../../components";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

export default function Managers() {
  const { getUsers } = useUsersApi();

  const [users, setUsers] = useState<GetUsersOutputType>({
    items: [],
    total: 0,
  });

  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);

  const [search, setSearch] = useState("");

  const fetchUsers = useCallback(async () => {
    const customQuery: GetUsersInputType = {
      skip: page * take,
      take,
    };

    if (search) {
      customQuery.search = search;
    }

    const _users = await getUsers(customQuery);
    setUsers(_users);
  }, [page, take, search, getUsers]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const rows = users.items.map((user) => ({ user }));

  return (
    <>
      <HeaderLink icon={<PersonAddAlt1Icon />} text="Add Manager" to="add" />
      <ManagerTable
        rows={rows}
        total={users.total}
        page={page}
        take={take}
        setPage={setPage}
        search={search}
        setSearch={setSearch}
      />
    </>
  );
}
