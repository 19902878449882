import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FormHeader from "./FormHeader";
import ResidentsForm from "./ResidentsForm";
import CollectionsHistory from "./CollectionsHistory";
import { useResidentsApi, usePhotosApi } from "../apis";
import { useMe } from "../store/me";
import {
  Resident,
  PENDING,
  REQUEST_MOVE_IN,
  MOVED_IN,
  REQUEST_MOVE_OUT,
  MOVED_OUT,
} from "../classes/resident";
import { Photo } from "../classes/photo";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { Grid, Button } from "@mui/material";
import { convertLocalToUTC } from "../utils/date-helpers";
import startOfDay from "date-fns/startOfDay";

interface ResidentsFormPageProps {
  residentId?: number;
  status: string;
  title: string;
  saveButtonText: string;
  onSave: (resident: Resident) => Promise<Resident>;
  onDelete?: (resident: Resident) => Promise<void>;
}

export default function ResidentsFormPage({
  title,
  status,
  residentId,
  onSave,
  onDelete,
  saveButtonText,
}: ResidentsFormPageProps) {
  const navigate = useNavigate();
  const { isAdmin } = useMe();

  const [isDirty, setIsDirty] = useState(false);
  const [resident, setResident] = useState(new Resident({ status }));

  const { getResident, getResidentPhotos, addResidentPhoto } =
    useResidentsApi();
  const { deletePhoto } = usePhotosApi();

  const fetchResident = useCallback(async () => {
    const _resident = await getResident(residentId);

    let initialStateDirty = false;

    // If executing a user flow (i.e., moved_in, request_move_out)
    // change the status here.
    if (
      status === PENDING ||
      status === REQUEST_MOVE_IN ||
      status === MOVED_IN ||
      status === REQUEST_MOVE_OUT ||
      status === MOVED_OUT
    ) {
      _resident.status = status;
      initialStateDirty = true;
    }

    if (status === MOVED_IN) {
      // If the current resident doesn't have a moveInDate saved
      // from before, we should set it to today's date.
      if (!_resident.moveInDate) {
        _resident.moveInDate = convertLocalToUTC(startOfDay(new Date()));
      }
    }

    if (status === MOVED_OUT) {
      // If the current resident doesn't have a moveOutDate saved
      // from before, we should set it to today's date.
      if (!_resident.moveOutDate) {
        _resident.moveOutDate = convertLocalToUTC(startOfDay(new Date()));
      }
    }

    const newResident = new Resident(_resident);

    setResident(newResident);
    setIsDirty(initialStateDirty);
  }, [getResident, residentId, status]);

  useEffect(() => {
    if (residentId) {
      fetchResident();
    }
  }, [residentId, fetchResident]);

  // Have any background resident changes to be synced
  // with the user's UI. This is necessary because a manager
  // can add a collection when editing/viewing a Resident. When
  // adding a Collection that changes the Resident's `rentDueDate`,
  // it updates the Resident in the background. If the Manager
  // happens to "save" the Resident, it will overwrite any changes
  // that happened to the Resident in the background, for example,
  // the `rentDueDate` being cleared.

  const [newPhotos, setNewPhotos] = useState<File[]>([]);
  const [removePhotos, setRemovePhotos] = useState<Photo[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [photos, setPhotos] = useState([]);

  const fetchPhotos = useCallback(async () => {
    const _photos = await getResidentPhotos(residentId);
    setPhotos(_photos.items);
  }, [getResidentPhotos, residentId]);

  useEffect(() => {
    if (residentId) {
      fetchPhotos();
    }
  }, [residentId, fetchPhotos]);

  const addPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files) return;

    const imageFile = e.target.files[0];

    setIsDirty(true);

    // Resize the incoming image to a small resolution for:
    // 1) Lower bandwidth when uploading images.
    // 2) Lower storage usage.

    const reader = new FileReader();
    reader.onload = function (readerEvent) {
      const img = document.createElement("img");
      img.onload = function () {
        // Dynamically create a canvas element
        const canvas = document.createElement("canvas");
        const max_size = 640;
        let width = img.width;
        let height = img.height;
        if (width > height) {
          if (width > max_size) {
            height *= max_size / width;
            width = max_size;
          }
        } else {
          if (height > max_size) {
            width *= max_size / height;
            height = max_size;
          }
        }
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");

        if (!ctx) return;

        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          if (!blob) return;

          const newFile = new File([blob], imageFile.name, {
            type: imageFile.type,
          });
          setNewPhotos([...newPhotos, newFile]);
        }, imageFile.type);
      };

      if (!readerEvent.target) return;
      img.src = readerEvent.target.result as string;
    };
    reader.readAsDataURL(imageFile);
  };

  const removeNewPhoto = (file: File) => {
    setNewPhotos(newPhotos.filter((f) => f !== file));
    setIsDirty(true);
  };

  const removePhoto = (photo: Photo) => {
    setRemovePhotos([...removePhotos, photo]);

    const newPhotos = photos.filter((p) => p !== photo);
    setPhotos(newPhotos);

    setIsDirty(true);
  };

  const _onSave = async () => {
    try {
      setIsUploading(true);

      const savedResident = await onSave(resident);

      // Add the new Photos
      const newPhotoPromises = newPhotos.map(async (photo) => {
        const photoData = new FormData();
        photoData.append("photo", photo);

        return addResidentPhoto(savedResident.id, photoData);
      });

      // Remove the deleted Photos
      const removePhotoPromises = removePhotos.map(async (photo) => {
        return deletePhoto(photo.id);
      });

      await Promise.all([...newPhotoPromises, ...removePhotoPromises]);

      navigate(-1);
    } catch (e) {
      if (e instanceof Error) {
        alert(e.message);
      }
    } finally {
      setIsUploading(false);
    }
  };

  const _onDelete = async () => {
    onDelete && (await onDelete(resident));
    navigate(-1);
  };

  if (!resident) {
    return null;
  }

  return (
    <Grid width="100%" spacing="pb-8">
      <FormHeader
        title={title}
        primaryButton={{
          onClick: _onSave,
          text: isUploading
            ? "Uploading..."
            : saveButtonText
            ? saveButtonText
            : "Save",
          endIcon: <SaveIcon />,
          disabled: !isDirty || isUploading,
        }}
      />

      <ResidentsForm
        status={status}
        resident={resident}
        setResident={(r) => {
          setResident(r);
          setIsDirty(true);
        }}
        photos={photos}
        newPhotos={newPhotos}
        addPhoto={addPhoto}
        removePhoto={removePhoto}
        removeNewPhoto={removeNewPhoto}
      />

      {/* TODO: This is calling the collection fetch on every resident change */}
      <CollectionsHistory resident={resident} fetchResident={fetchResident} />

      {status === "VIEW" && isAdmin() && (
        <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
          <Button color="error" onClick={_onDelete} startIcon={<DeleteIcon />}>
            Delete Resident
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
