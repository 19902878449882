import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

declare global {
  interface Window {
    ReactNativeWebView?: any;
  }
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    function onAuthStateChanged(_user: any) {
      setUser(_user);
      setLoading(false);

      if (_user) {
        if (
          window &&
          window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage
        ) {
          const message = {
            accessToken: _user.signInUserSession.idToken.jwtToken,
            url: `${process.env.REACT_APP_API_URL}/device-info`,
          };
          console.log("postMessage", JSON.stringify(message));
          window.ReactNativeWebView.postMessage(JSON.stringify(message));
        }
      }
    }

    Auth.currentAuthenticatedUser()
      .then(onAuthStateChanged)
      // on error, redirect to login
      .catch(() => (window.location.href = "/login"))
      .finally(() => setLoading(false));
    Auth.configure({
      onAuthStateChanged: onAuthStateChanged,
    });

    return () => {
      Auth.configure({
        onAuthStateChanged: null,
      });
    };
  }, []);

  if (loading) {
    return null;
  }

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
