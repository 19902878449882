import { PRODUCT_CODES } from "../classes/collection-item";
import addDays from "date-fns/addDays";
import addWeeks from "date-fns/addWeeks";

export function convertUTCToLocal(date: Date): Date {
  const newDate = new Date(date);
  const timezoneOffset = newDate.getTimezoneOffset() * 60000;
  return new Date(newDate.getTime() + timezoneOffset);
}

export function convertLocalToUTC(date: Date): Date {
  const newDate = new Date(date);
  const timezoneOffset = newDate.getTimezoneOffset() * 60000;
  return new Date(newDate.getTime() - timezoneOffset);
}

export function bumpRentDueDateLocal(
  rentDueDateLocal: Date,
  collectionItemCode: string,
  dateLocal?: Date
) {
  let nextRentDueDateLocal: Date;

  switch (collectionItemCode) {
    case PRODUCT_CODES.CUSTOM:
      // Dates need to be handled manually
      // If no date is passed, we'll use the current date as a NOOP.
      nextRentDueDateLocal = dateLocal ? dateLocal : rentDueDateLocal;
      break;
    case PRODUCT_CODES.MONTH_1__41000:
    case PRODUCT_CODES.MONTH_1__50000:
    case PRODUCT_CODES.MONTH_1__8000:
    case PRODUCT_CODES.STORAGE_HALF_BAY_7500:
    case PRODUCT_CODES.STORAGE_FULL_BAY_10000:
      // If the previous rent due date is Jan 30th or 31st,
      // force the next rent due date to be Mar 1st.
      if (
        rentDueDateLocal.getMonth() === 0 &&
        (rentDueDateLocal.getDate() === 29 || rentDueDateLocal.getDate() === 30)
      ) {
        nextRentDueDateLocal = rentDueDateLocal;
        nextRentDueDateLocal.setMonth(2);
        nextRentDueDateLocal.setDate(1);
      }
      // Else we simply increase the month number.
      else {
        nextRentDueDateLocal = rentDueDateLocal;
        nextRentDueDateLocal.setMonth(nextRentDueDateLocal.getMonth() + 1);
      }
      break;
    case PRODUCT_CODES.MONTH_1__75000__GEO:
    case PRODUCT_CODES.MONTH_1__95000__GEO:
      nextRentDueDateLocal = addDays(rentDueDateLocal, 30);
      break;
    case PRODUCT_CODES.WEEK_1__11500:
    case PRODUCT_CODES.WEEK_1__12500:
      nextRentDueDateLocal = addWeeks(rentDueDateLocal, 1);
      break;
    case PRODUCT_CODES.WEEK_2__21000:
    case PRODUCT_CODES.WEEK_2__25000:
      nextRentDueDateLocal = addWeeks(rentDueDateLocal, 2);
      break;
    case PRODUCT_CODES.DAY_1__2000:
      nextRentDueDateLocal = addDays(rentDueDateLocal, 1);
      break;
    case PRODUCT_CODES.DAY_2__4000:
      nextRentDueDateLocal = addDays(rentDueDateLocal, 2);
      break;
    case PRODUCT_CODES.DAY_3__6000:
      nextRentDueDateLocal = addDays(rentDueDateLocal, 3);
      break;
    case PRODUCT_CODES.DAY_4__8000:
      nextRentDueDateLocal = addDays(rentDueDateLocal, 4);
      break;
    default:
      throw new Error("Invalid code");
  }

  return nextRentDueDateLocal;
}
