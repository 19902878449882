import React from "react";
import styled from "styled-components";
import {
  TableRow,
  TableBody,
  TableHeader,
  TableSelectedSectionButton,
  TableCell,
  TableSearchField,
  Typography,
} from "./index";

import { useNavigate } from "react-router-dom";
import { colors } from "../constants";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import { Location } from "../classes/location";

const Header = () => (
  <>
    <TableCell basis="120px">Name</TableCell>
    <TableCell basis="60px">Gender</TableCell>
    <TableCell basis="110px">Phone Number</TableCell>
    <TableCell basis="300px">Address</TableCell>
  </>
);

const EmptyView = () => (
  <Typography
    type="h4"
    bold
    align="center"
    spacing="mt-8"
    color={colors.grey[5]}
  >
    No Location
  </Typography>
);

interface RecordProps {
  location: Location;
}

interface RowProps {
  row: {
    location?: Location;
  };
}

const Row = ({ row, ...props }: RowProps) => (
  <TableRow row={row} SelectedSectionElem={SelectedSection} {...props}>
    <TableCell basis="120px" semiBold>
      {row.location && row.location.name}
    </TableCell>
    <TableCell basis="60px" color={colors.grey[3]}>
      {row.location && row.location.gender}
    </TableCell>
    <TableCell basis="110px" color={colors.grey[3]}>
      {row.location && row.location.phoneNumber}
    </TableCell>
    <TableCell basis="300px" color={colors.grey[3]}>
      {row.location && row.location.address},{" "}
      {row.location && row.location.city}, {row.location && row.location.state}
    </TableCell>
  </TableRow>
);

interface SelectedSectionProps {
  row: RecordProps;
}

const SelectedSection = ({ row }: SelectedSectionProps) => {
  const navigate = useNavigate();

  const goTo = (path: string) => () => navigate(`${path}/${row.location.id}`);

  return (
    <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
      View
    </TableSelectedSectionButton>
  );
};

const LocationTableStyles = styled.div`
  height: calc(100% - 64px - 52px);
`;

interface LocationTableProps {
  rows: RecordProps[];
  total: number;
  page: number;
  take: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const LocationTable = ({
  rows,
  total,
  page,
  take,
  setPage,
  search,
  setSearch,
}: LocationTableProps) => {
  return (
    <LocationTableStyles>
      <TableSearchField
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <TableHeader>
        <Header />
      </TableHeader>
      <TableBody
        rows={rows}
        total={total}
        page={page}
        take={take}
        setPage={setPage}
        RowElem={Row}
        EmptyViewElem={EmptyView}
      />
    </LocationTableStyles>
  );
};

export default LocationTable;
