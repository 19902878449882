import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";

interface UserConstructorType extends DatabaseObjectConstructorType {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  roles: string[];
  disabled: boolean;
}

export class User extends DatabaseObject {
  firstName = "";
  lastName = "";
  phoneNumber = "";
  email = "";
  roles: string[] = [];
  disabled = false;

  constructor(data?: UserConstructorType) {
    super(data);

    if (!data) return;

    if (data.firstName) this.firstName = data.firstName;
    if (data.lastName) this.lastName = data.lastName;
    if (data.phoneNumber) this.phoneNumber = data.phoneNumber;

    if (data.email) this.email = data.email;
    if (data.roles) this.roles = data.roles;
    if (data.disabled) this.disabled = data.disabled;
  }

  clone() {
    return new User(this);
  }
}
