import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";
import {
  ActivityTypeFilter,
  LocationFilter,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableSearchField,
  TableSelectedSectionButton,
} from "./index";

import { useNavigate } from "react-router-dom";
import format from "date-fns/format";
import { colors } from "../constants";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { TableFilterModal } from "./TableFilter";
import { Resident, Activity, Location } from "../classes";

interface RecordProps {
  resident: Resident;
  activity: Activity;
  location?: Location;
}

const Header = () => (
  <>
    <TableCell basis="200px">Resident Name</TableCell>
    <TableCell basis="100px">Date</TableCell>
    <TableCell basis="130px">Activity Type</TableCell>
    <TableCell basis="80px">Location</TableCell>
    <TableCell basis="100px">Supervision</TableCell>
  </>
);

const EmptyView = () => (
  <Typography
    variant="h6"
    align="center"
    sx={{
      color: colors.grey[5],
      marginTop: 8,
    }}
  >
    No Activities
  </Typography>
);

interface RowProps {
  row: {
    resident?: Resident;
    location?: Location;
    activity?: Activity;
  };
}

const Row = ({ row, ...props }: RowProps) => (
  <TableRow row={row} SelectedSectionElem={SelectedSection} {...props}>
    <TableCell basis="200px" semiBold>
      {row.resident && `${row.resident.firstName} ${row.resident.lastName}`}
    </TableCell>
    <TableCell basis="100px" color={colors.grey[3]}>
      {row.activity &&
        row.activity.createdAt &&
        format(new Date(row.activity.createdAt), "P")}
    </TableCell>
    <TableCell basis="130px" color={colors.grey[3]}>
      {row.activity && row.activity.type.replaceAll("_", " ").toUpperCase()}
    </TableCell>
    <TableCell basis="80px" color={colors.grey[3]}>
      {row.location && row.location.name}
    </TableCell>
    <TableCell basis="100px" color={colors.grey[3]}>
      {row.resident && row.resident.supervision}
    </TableCell>
  </TableRow>
);

interface SelectedSectionProps {
  row: RecordProps;
}

const SelectedSection = ({ row }: SelectedSectionProps) => {
  const navigate = useNavigate();

  const goTo = (path: string) => () => navigate(`${path}/${row.activity.id}`);

  return (
    <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
      View
    </TableSelectedSectionButton>
  );
};

// 52px - Quick search
const ActivityTableStyles = styled.div`
  height: calc(100% - 52px);
`;

interface ActivityTableProps {
  rows: RecordProps[];
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  total: number;
  page: number;
  take: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const ActivityTable = ({
  rows,
  search,
  setSearch,
  total,
  page,
  take,
  setPage,
}: ActivityTableProps) => {
  const filterContent = [LocationFilter, ActivityTypeFilter];

  return (
    <ActivityTableStyles>
      <TableFilterModal filterContent={filterContent} />
      <TableSearchField
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        filterContent={filterContent}
      />
      <TableHeader>
        <Header />
      </TableHeader>
      <TableBody
        rows={rows}
        total={total}
        page={page}
        take={take}
        setPage={setPage}
        RowElem={Row}
        EmptyViewElem={EmptyView}
      />
    </ActivityTableStyles>
  );
};

export default ActivityTable;
