import React from "react";
import { FormHeader, TextField, Toggle } from "./index";
import { User } from "../classes";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Button } from "@mui/material";

interface ManagerFormProps {
  title: string;
  manager: User;
  setManager: React.Dispatch<React.SetStateAction<User>>;
  onSave: () => void;
  onDelete?: () => void;
}

export default function ManagerForm({
  title,
  manager,
  setManager,
  onSave,
  onDelete,
}: ManagerFormProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    switch (e.target.name) {
      case "isAdmin":
        const roles = [];
        if (e.target.checked) {
          roles.push("ADMIN");
        }
        setManager(new User({ ...manager, roles }));
        break;
      default:
        setManager(
          new User({
            ...manager,
            [e.target.name]: value,
          })
        );
        break;
    }
  };

  return (
    <>
      <FormHeader
        title={title}
        primaryButton={{
          onClick: onSave,
          text: "Save Manager",
          startIcon: <SaveIcon />,
        }}
      />
      <div className="content">
        <form>
          <TextField
            label="First Name"
            name="firstName"
            value={manager.firstName}
            onChange={handleChange}
          />

          <TextField
            label="Last Name"
            name="lastName"
            value={manager.lastName}
            onChange={handleChange}
          />

          <TextField
            label="Phone Number"
            name="phoneNumber"
            value={manager.phoneNumber}
            onChange={handleChange}
          />

          <TextField
            label="Email"
            name="email"
            value={manager.email}
            onChange={handleChange}
          />

          <Toggle
            label="Is Admin?"
            name="isAdmin"
            value={manager.roles.includes("ADMIN")}
            onChange={handleChange}
          />

          {manager.id && (
            <Toggle
              label="Is Disabled?"
              name="disabled"
              value={manager.disabled}
              onChange={handleChange}
            />
          )}
        </form>

        {onDelete && (
          <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
            <Button color="error" onClick={onDelete} startIcon={<DeleteIcon />}>
              Delete Manager
            </Button>
          </Grid>
        )}
      </div>
    </>
  );
}
