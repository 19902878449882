import React from "react";
import styled from "styled-components";
import { Layout, Typography } from "./index";
import { NavLink } from "react-router-dom";
import { colors, roundCorners } from "../constants";
import useSpacingProps from "../utils/spacing-props";
import { useMe } from "../store/me";

const CollectionTab = styled(({ children, ...props }) => (
  <NavLink {...props}>
    <Typography spacing="m-0" bold>
      {children}
    </Typography>
  </NavLink>
))`
  ${useSpacingProps};
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  border: 1px solid ${colors.green[3]};
  background-color: ${colors.white};
  color: ${colors.grey[3]};

  transition: 0.1s;

  &:hover {
    background-color: ${colors.grey[6]};
  }

  &.active {
    background-color: ${colors.green[3]};
    color: ${colors.font.light};
  }

  &:first-child {
    border-radius: ${roundCorners[0]} 0 0 ${roundCorners[0]};
  }

  &:last-child {
    border-radius: 0 ${roundCorners[0]} ${roundCorners[0]} 0;
  }
`;

const CollectionsTabs = styled((props) => {
  const { isAdmin } = useMe();
  return (
    <Layout
      flex
      justifyContent="center"
      alignItems="center"
      {...props}
      height="3rem"
    >
      <CollectionTab to="due">Due</CollectionTab>
      <CollectionTab to="collection-overdue">Overdue</CollectionTab>
      <CollectionTab to="rejected">Rejected</CollectionTab>
      {isAdmin() && <CollectionTab to="audit">Audit</CollectionTab>}
    </Layout>
  );
})`
  border-bottom: 1px solid ${colors.grey[5]};
`;

export default CollectionsTabs;
