import React from "react";
import {
  TextField,
  Button,
  Grid,
  Select,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  CardActions,
  SelectChangeEvent,
} from "@mui/material";
import { DatePicker } from "@mui/lab";

import { extractOptions } from "./index";

import { Collection } from "../classes/collection";
import { convertLocalToUTC, convertUTCToLocal } from "../utils/date-helpers";
import {
  CollectionItem,
  COLLECTION_ITEM_TYPES,
  PRODUCT_CODES,
  DEBT_OPTIONS,
  COLLECTION_OPTIONS,
  COLLECTION_OPTIONS_NO_PRODUCTS,
  findOption,
} from "../classes/collection-item";
import { Resident } from "../classes/resident";

import CustomPrice from "./CustomPrice";

interface CollectionItemRowProps {
  index: number;
  item: CollectionItem;
  resident: Resident;
  collection: Collection;
  collections: { items: Collection[]; total: number; overallBalance: number };
  setCollection: React.Dispatch<React.SetStateAction<Collection>>;
  disabled?: boolean;
}

export default function CollectionItemRow({
  index,
  item,
  resident,
  collection,
  collections,
  setCollection,
  disabled,
}: CollectionItemRowProps) {
  let availableOptions;
  if (
    collection.hasProductAlready() &&
    item.type !== COLLECTION_ITEM_TYPES.PRODUCT
  ) {
    availableOptions = COLLECTION_OPTIONS_NO_PRODUCTS;
  } else {
    availableOptions = COLLECTION_OPTIONS;
  }

  const overallBalance = collections.overallBalance;

  if (overallBalance < 0 || item.isDebt()) {
    availableOptions = {
      Debt: DEBT_OPTIONS,
      ...availableOptions,
    };
  }

  const optionElems = extractOptions(availableOptions);

  const changeCollectionItem = (e: SelectChangeEvent) => {
    const collectionCode = e.target.value;
    const collectionOption = findOption(collectionCode);

    const newCollection = new Collection(collection);
    const collectionItem = CollectionItem.makeFromOption(collectionOption);
    const itemReplaced = newCollection.replaceItem(
      index,
      collectionItem
    ) as CollectionItem;

    if (collectionItem.isProduct() || itemReplaced.isProduct()) {
      newCollection.setRentDueDate(resident);
    }

    setCollection(newCollection);
  };

  const handlePriceChange = (price: number) => {
    const newCollection = new Collection(collection);

    // Overwrite the price
    const collectionItem = newCollection.getItem(index) as CollectionItem;
    collectionItem.price = price;

    setCollection(newCollection);
  };

  const handleRentDueDate = (date: Date | null) => {
    if (!date) return;

    const newCollection = new Collection(collection);
    const adjustedDate = convertLocalToUTC(date);
    newCollection.setRentDueDate(resident, adjustedDate);
    setCollection(newCollection);
  };

  const removeCollectionItem = () => {
    const newCollection = new Collection(collection);
    const item = newCollection.removeItem(index) as CollectionItem;

    // if Item was a product, we need to update the Resident's rent due date
    if (item.isProduct()) {
      newCollection.setRentDueDate(resident);
    }
    setCollection(newCollection);
  };

  return (
    <Card style={{ marginTop: index > 0 ? "1rem" : "" }} variant="outlined">
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item style={{ width: "100%" }}>
            <FormControl
              variant="outlined"
              disabled={disabled}
              required
              fullWidth
            >
              <InputLabel htmlFor={`collection-item-row-${index}`}>
                {(item.type && item.type.replace("_", " ")) || "item"}
              </InputLabel>
              <Select
                native
                label={item.type || "item"}
                value={item.code}
                onChange={changeCollectionItem}
                id={`collection-item-row-${index}`}
                name="item"
              >
                <option aria-label="None" value="" />
                {optionElems}
              </Select>
            </FormControl>

            <Grid
              container
              style={{ paddingLeft: "1rem", marginTop: "0.25rem" }}
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              {(item.isCustomProduct() ||
                item.isOther() ||
                item.isDebt() ||
                item.isPrepayment()) && (
                <Grid item style={{ marginTop: "0.5rem" }}>
                  <CustomPrice
                    onChange={handlePriceChange}
                    value={item.price}
                    maxValue={item.isDebt() ? Math.abs(overallBalance) : null}
                    disabled={disabled}
                  />
                </Grid>
              )}

              {!resident.isPending() &&
                !resident.isRequestMoveIn() &&
                item.type === COLLECTION_ITEM_TYPES.PRODUCT &&
                item.rentDueDate && (
                  <Grid item style={{ marginTop: "0.5rem" }}>
                    <DatePicker
                      label="Next Rent Due Date:"
                      value={convertUTCToLocal(item.rentDueDate)}
                      onChange={handleRentDueDate}
                      disabled={disabled || item.code !== PRODUCT_CODES.CUSTOM}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          sx={{ width: 170 }}
                          size="small"
                          label="Next Rent Due Date:"
                          disabled={disabled}
                        />
                      )}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

      {!disabled && (
        <CardActions>
          <Grid container justifyContent="flex-end">
            <Button
              color="secondary"
              size="small"
              onClick={removeCollectionItem}
            >
              Remove
            </Button>
          </Grid>
        </CardActions>
      )}
    </Card>
  );
}
