import React, { useState } from "react";
import styled from "styled-components";
import format from "date-fns/format";
import {
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableSearchField,
  TableSelectedSectionButton,
  Typography,
  useTableFilter,
} from "./index";
import { useCollectionsApi } from "../apis";

import { CollectionItem } from "../classes/collection-item";
import { Collection } from "../classes/collection";
import { Location } from "../classes/location";
import { Resident } from "../classes/resident";

import { colors } from "../constants";
import {
  faEye,
  faThumbsUp,
  faFire,
  faAlignLeft,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ChecksOnlyFilter,
  LocationFilter,
  TableFilterModal,
} from "./TableFilter";
import { formatMoney } from "../utils";

interface RecordProps {
  resident: Resident;
  location?: Location;
  collection: Collection;
}

const HeaderStatsStyles = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.grey[6]};

  .count {
    padding-left: 4px;
  }

  .total {
    padding-right: 4px;
  }
`;

interface HeaderStatsProps {
  rows: RecordProps[];
}

const HeaderStats = ({ rows }: HeaderStatsProps) => (
  <HeaderStatsStyles>
    <div className="count">Count: {rows.length}</div>

    <div className="total">
      Total:{" "}
      {formatMoney(
        rows.reduce(
          (sum, row) => sum + row.collection.amountPaidWithoutCredit(),
          0
        )
      )}
    </div>
  </HeaderStatsStyles>
);

const Header = () => (
  <>
    <TableCell basis="200px">Name</TableCell>
    <TableCell basis="100px">Date Paid</TableCell>
    <TableCell basis="66px">Location</TableCell>
    <TableCell basis="57px">Check #</TableCell>
    <TableCell basis="100px">Collection</TableCell>
    <TableCell basis="43px">Notes</TableCell>
    <TableCell basis="49px">Delete</TableCell>
  </>
);

const EmptyView = () => (
  <Typography
    type="h4"
    bold
    align="center"
    spacing="mt-8"
    color={colors.grey[5]}
  >
    No Collections
  </Typography>
);

interface RowProps {
  row: {
    resident?: Resident;
    location?: Location;
    collection?: Collection;
  };
}

const Row = ({ row, ...props }: RowProps) => (
  <TableRow row={row} SelectedSectionElem={SelectedSection} {...props}>
    <TableCell basis="200px" semiBold>
      {row.resident && `${row.resident.lastName}, ${row.resident.firstName}`}
    </TableCell>
    <TableCell basis="100px" color={colors.grey[3]}>
      {row.collection &&
        row.collection.datePaid &&
        format(new Date(row.collection.datePaid), "P")}
    </TableCell>
    <TableCell basis="66px" color={colors.grey[3]}>
      {row.location && row.location.name}
    </TableCell>
    <TableCell basis="57px" color={colors.grey[3]}>
      {row.collection &&
        row.collection.payments &&
        row.collection.payments
          .filter((payment) => payment.checkNumber)
          .map((payment) => payment.checkNumber)
          .join(", ")}
    </TableCell>
    <TableCell basis="100px" color={colors.grey[3]}>
      {row.collection && formatMoney(row.collection.amountPaidWithoutCredit())}
    </TableCell>
    <TableCell basis="43px" color={colors.grey[3]}>
      {row.collection && row.collection.notes && (
        <FontAwesomeIcon icon={faAlignLeft} />
      )}
    </TableCell>
    <TableCell basis="49px" color={colors.grey[3]}>
      {row.collection && row.collection.isPendingDelete() ? (
        <FontAwesomeIcon icon={faTrash} />
      ) : (
        ""
      )}
    </TableCell>
  </TableRow>
);

interface SelectedSectionProps {
  row: RecordProps;
  onView: (row: RecordProps) => void;
  fetchData: () => Promise<void>;
}

const SelectedSection = ({ row, onView, fetchData }: SelectedSectionProps) => {
  const { updateCollection } = useCollectionsApi();

  const handleView = () => onView(row);

  const handleApprove = async () => {
    const newCollection = new Collection(row.collection);
    newCollection.approve();
    await updateCollection(newCollection.id, newCollection.prepareForApi());

    await fetchData();
  };

  const handleReject = async () => {
    const newCollection = new Collection(row.collection);
    newCollection.reject();
    await updateCollection(newCollection.id, newCollection.prepareForApi());

    await fetchData();
  };

  return (
    <>
      {!row.collection.isPendingDelete() && (
        <TableSelectedSectionButton icon={faFire} onClick={handleReject}>
          Reject
        </TableSelectedSectionButton>
      )}

      <TableSelectedSectionButton icon={faEye} onClick={handleView}>
        View
      </TableSelectedSectionButton>

      {!row.collection.isPendingDelete() && (
        <TableSelectedSectionButton icon={faThumbsUp} onClick={handleApprove}>
          Approve
        </TableSelectedSectionButton>
      )}
    </>
  );
};

// 48px - Header Tabs
// 52px - Quick search
// 22px - Sums Header
// 45px - Table Header
// 5px - Good measure
const AuditTableStyles = styled.div`
  height: calc(100% - 48px - 52px - 22px - 45px - 5px);
`;

interface AuditTableProps {
  rows: RecordProps[];
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  total: number;
  page: number;
  take: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onView: (row: {
    resident?: Resident;
    location?: Location;
    collection?: Collection;
  }) => void;
  fetchData: () => Promise<void>;
}

export const AuditTable = ({
  rows,
  search,
  setSearch,
  total,
  page,
  take,
  setPage,
  onView,
  fetchData,
}: AuditTableProps) => {
  const filterContent = [ChecksOnlyFilter, LocationFilter];

  return (
    <AuditTableStyles>
      <TableFilterModal filterContent={filterContent} />
      <TableSearchField
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        filterContent={filterContent}
      />
      <HeaderStats rows={rows} />
      <TableHeader>
        <Header />
      </TableHeader>
      <TableBody
        rows={rows}
        total={total}
        page={page}
        take={take}
        setPage={setPage}
        RowElem={Row}
        EmptyViewElem={EmptyView}
        onView={onView}
        fetchData={fetchData}
      />
    </AuditTableStyles>
  );
};
