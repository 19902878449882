import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Activity from "./Activity";
import ActivityView from "./ActivityView";

export default function ActivityRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Activity />} />
      <Route path="view/:id" element={<ActivityView />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
