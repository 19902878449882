import React, { useState, useCallback, useEffect } from "react";
import { HeaderLink, LocationTable } from "../../../components";
import HomeIcon from "@mui/icons-material/Home";
import {
  useLocationsApi,
  GetLocationsInputType,
  GetLocationsOutputType,
} from "../../../apis/locations";

export default function Locations() {
  const { getLocations } = useLocationsApi();
  const [locations, setLocations] = useState<GetLocationsOutputType>({
    items: [],
    total: 0,
  });

  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);

  const [search, setSearch] = useState("");

  const fetchData = useCallback(async () => {
    const customQuery: GetLocationsInputType = {
      skip: page * take,
      take,
    };

    if (search) {
      customQuery.search = search;
    }

    const _locations = await getLocations(customQuery);
    setLocations(_locations);
  }, [page, take, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const rows = locations.items.map((location) => ({
    location,
  }));

  return (
    <>
      <HeaderLink icon={<HomeIcon />} text="Add Location" to="add" />
      <LocationTable
        rows={rows}
        total={locations.total}
        page={page}
        take={take}
        setPage={setPage}
        search={search}
        setSearch={setSearch}
      />
    </>
  );
}
