import React from "react";
import { convertLocalToUTC } from "./date-helpers";
import startOfDay from "date-fns/startOfDay";

export function handleDateChange<T>(
  key: string,
  object: T,
  setObject: React.Dispatch<React.SetStateAction<T>>,
  Class: new (...args: any[]) => T,
  ignoreTime = false
) {
  return (date: Date | null) => {
    let adjustedDate = null;

    try {
      if (date) {
        adjustedDate = convertLocalToUTC(ignoreTime ? startOfDay(date) : date);
      }

      const newObject = new Class({
        ...object,
        [key]: adjustedDate,
      });

      setObject(newObject);
    } catch (e) {
      console.log(e);
    }
  };
}
