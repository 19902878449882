import React from "react";
import PropTypes from "prop-types";
import { Grid, InputLabel, Switch } from "@mui/material";

const Toggle = ({ label, name, onChange, value, disabled }) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Switch
        checked={value}
        name={name}
        onChange={onChange}
        disabled={disabled}
      />
    </Grid>
  );
};

Toggle.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default Toggle;
