import React, { useState, useCallback, useEffect } from "react";
import {
  CollectionModal,
  RejectedTable,
  useTableFilter,
} from "../../../components";
import { Collection, CollectionStatusTypes } from "../../../classes/collection";
import { Resident } from "../../../classes/resident";
import { Location } from "../../../classes/location";
import {
  useCollectionsApi,
  GetCollectionsInputType,
  GetCollectionsOutputType,
} from "../../../apis/collections";

const Rejected = () => {
  const { getCollections } = useCollectionsApi();
  const { locations } = useTableFilter();

  const [active, setActive] = useState(false);
  const [collection, setCollection] = useState(new Collection());
  const [resident, setResident] = useState(new Resident());
  const [collections, setCollections] = useState<GetCollectionsOutputType>({
    items: [],
    total: 0,
    overallBalance: 0,
  });
  const [residentCollections, setResidentCollections] =
    useState<GetCollectionsOutputType>({
      items: [],
      total: 0,
      overallBalance: 0,
    });

  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);

  const [search, setSearch] = useState("");

  const fetchData = useCallback(async () => {
    const customQuery: GetCollectionsInputType = {
      skip: page * take,
      take,
      statuses: CollectionStatusTypes.REJECTED,
    };

    if (locations.length > 0) {
      customQuery.locations = locations;
    }

    if (search) {
      customQuery.search = search;
    }

    const _collections = await getCollections(customQuery);
    setCollections(_collections);
  }, [page, take, locations, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const rows = collections.items.map((collection) => ({
    collection,
    resident: new Resident(collection.resident),
    location:
      collection.resident && collection.resident.location
        ? new Location(collection.resident.location)
        : undefined,
  }));

  const handleViewCollection = async (row: {
    collection?: Collection;
    resident?: Resident;
  }) => {
    setCollection(row.collection as Collection);
    setResident(row.resident as Resident);

    // Fetch all of the resident's collections
    // because we need it for "Overall Balance"
    const _residentCollections = await getCollections({
      residentId: (row.resident as Resident).id,
    });

    setResidentCollections(_residentCollections);

    setActive(true);
  };

  return (
    <>
      <RejectedTable
        rows={rows}
        total={collections.total}
        page={page}
        take={take}
        setPage={setPage}
        onView={handleViewCollection}
        search={search}
        setSearch={setSearch}
      />

      <CollectionModal
        active={active}
        closeModal={() => setActive(false)}
        collection={collection}
        collections={residentCollections}
        resident={resident}
        onCallback={fetchData}
      />
    </>
  );
};

export default Rejected;
