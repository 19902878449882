import React, { useEffect, useState, useCallback } from "react";
import { HeaderLink, ResidentTable, useTableFilter } from "../../../components";
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// import { useApp } from "../../../store";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { useNavigate } from "react-router-dom";
import {
  useResidentsApi,
  GetResidentsInputType,
  GetResidentsOutputType,
} from "../../../apis/residents";

export default function Residents() {
  // const { state } = useApp();
  const navigate = useNavigate();
  const { getResidents } = useResidentsApi();
  const { status, locations, hasBPA, hasNoRentDueDate } = useTableFilter();
  const [modalActive, setModalActive] = useState(false);
  const [residents, setResidents] = useState<GetResidentsOutputType>({
    items: [],
    total: 0,
  });

  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);

  const [search, setSearch] = useState("");

  const fetchData = useCallback(async () => {
    const customQuery: GetResidentsInputType = {
      skip: page * take,
      take,
      statuses: status,
    };

    if (locations.length > 0) {
      customQuery.locations = locations;
    }

    if (hasBPA) {
      customQuery.hasBPA = hasBPA;
    }

    if (hasNoRentDueDate) {
      customQuery.hasNoRentDueDate = hasNoRentDueDate;
    }

    if (search) {
      customQuery.search = search;
    }

    const _residents = await getResidents(customQuery);

    setResidents(_residents);
  }, [
    page,
    take,
    status,
    locations,
    hasBPA,
    hasNoRentDueDate,
    search,
    getResidents,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const rows = residents.items.map((resident) => ({
    resident,
    location: resident.location,
  }));

  const handleCloseModal = () => setModalActive(false);
  const handleNewResident = () => setModalActive(true);
  const handleAddPending = () => navigate("pending");
  const handleAddReqMoveIn = () => navigate("request-move-in");

  return (
    <>
      <HeaderLink
        icon={<PersonAddAlt1Icon />}
        onClick={handleNewResident}
        text="Add Resident"
      />

      <ResidentTable
        rows={rows}
        total={residents.total}
        page={page}
        take={take}
        setPage={setPage}
        search={search}
        setSearch={setSearch}
      />

      <Dialog onClose={handleCloseModal} open={modalActive}>
        <DialogTitle>Add Resident</DialogTitle>
        <List>
          <ListItem button onClick={handleAddPending}>
            <ListItemIcon>
              <PersonAddAltIcon />
            </ListItemIcon>
            <ListItemText primary="Pending" />
          </ListItem>
          <ListItem button onClick={handleAddReqMoveIn}>
            <ListItemIcon>
              <PersonAddAlt1Icon />
            </ListItemIcon>
            <ListItemText primary="Req Move-in" />
          </ListItem>
        </List>
      </Dialog>
    </>
  );
}
