import { useCallback } from "react";
import { useApi, GetListInputType, GetListOutputType } from "../hooks";
import { Collection, CollectionConstructorType } from "../classes/collection";

export function useCollectionsApi() {
  const { fetchGETList, fetchGET, fetchPOST, fetchPUT, fetchDELETE } = useApi();

  const getCollection = useCallback(
    async (id) => {
      const c = await fetchGET(`collections/${id}`);
      return new Collection(c);
    },
    [fetchGET]
  );

  const getCollections = useCallback(
    async (queryParams) => {
      const cs = await fetchGETList("collections", queryParams);
      cs.items = cs.items.map(
        (c: CollectionConstructorType) => new Collection(c)
      );
      return cs;
    },
    [fetchGETList]
  );

  const addCollection = useCallback(
    async (data) => {
      const c = await fetchPOST("collections", data);
      return new Collection(c);
    },
    [fetchPOST]
  );

  const updateCollection = useCallback(
    async (id, data) => {
      const c = await fetchPUT(`collections/${id}`, data);
      return new Collection(c);
    },
    [fetchPUT]
  );

  const deleteCollection = useCallback(
    (id) => fetchDELETE(`collections/${id}`),
    [fetchDELETE]
  );

  return {
    getCollection,
    getCollections,
    addCollection,
    updateCollection,
    deleteCollection,
  };
}

export interface GetCollectionsInputType extends GetListInputType {
  statuses?: string[] | string;
  checksOnly?: boolean;
  locations?: number | number[];
  search?: string;
}

export interface GetCollectionsOutputType
  extends GetListOutputType<Collection> {
  overallBalance: number;
}
