import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Residents from "./Residents";
import Pending from "./Pending";
import RequestMoveIn from "./RequestMoveIn";
import MoveIn from "./MoveIn";
import LocationChange from "./LocationChange";
import RequestMoveOut from "./RequestMoveOut";
import MoveOut from "./MoveOut";
import ResidentEdit from "./ResidentEdit";

export default function ResidentsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Residents />} />
      <Route path="/pending" element={<Pending />} />
      <Route path="/request-move-in/:id" element={<RequestMoveIn />} />
      <Route path="/request-move-in" element={<RequestMoveIn />} />
      <Route path="/request-move-out/:id" element={<RequestMoveOut />} />
      <Route path="/move-in/:id" element={<MoveIn />} />
      <Route path="/move-out/:id" element={<MoveOut />} />
      <Route path="/view/:id" element={<ResidentEdit />} />
      <Route path="/location-change/:id" element={<LocationChange />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
