import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import "firebase/compat/storage";
// import { getStorage, ref, getDownloadURL } from "firebase/storage";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button, Layout } from ".";
import { colors } from "../constants";
import { Typography } from "@mui/material";
import { Photo } from "../classes/photo";

const UAPhotoStyled = styled.div`
  height: 50px;
  width: 50px;
  border: 1px solid #333;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  background-color: #aaa;
  position: relative;

  margin: 0 4px;

  &:first-child {
    margin-left: 0;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .ua-photo__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    transition: 0.3s;
  }

  & .ua-photo__overlay:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const UAPhoto = ({ photo, removePhoto }) => {
  const [displayViewer, setDisplayViewer] = useState(false);
  const handleClick = () => setDisplayViewer(!displayViewer);

  return (
    <>
      <UAPhotoStyled onClick={handleClick}>
        <div className="ua-photo__overlay"></div>
        <img src={photo.url} alt="ua" />
      </UAPhotoStyled>

      {displayViewer && (
        <UAPhotoViewer
          src={photo.url}
          close={handleClick}
          removePhoto={() => removePhoto(photo)}
        />
      )}
    </>
  );
};

UAPhoto.propTypes = {
  photo: PropTypes.objectOf(Photo).isRequired,
  removePhoto: PropTypes.func.isRequired,
};

const UAPhotoNew = styled(({ className, photoFile, removePhoto }) => {
  const [displayViewer, setDisplayViewer] = useState(false);
  const handleClick = () => setDisplayViewer(!displayViewer);

  const src = URL.createObjectURL(photoFile);

  return (
    <>
      <div className={className} onClick={handleClick}>
        <div className="ua-photo__overlay"></div>
        <img src={src} alt="ua" />
      </div>

      {displayViewer && (
        <UAPhotoViewer
          src={src}
          close={handleClick}
          removePhoto={() => removePhoto(photoFile)}
        />
      )}
    </>
  );
})`
  height: 50px;
  width: 50px;
  border: 1px solid #333;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  background-color: #aaa;
  position: relative;

  margin: 0 4px;

  &:first-child {
    margin-left: 0;
  }

  & img {
    width: 100%;
    height: 100%;
  }

  & .ua-photo__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    transition: 0.3s;
  }

  & .ua-photo__overlay:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const UAPhotoViewer = styled(({ className, src, close, removePhoto }) => (
  <div className={className} onClick={close}>
    <Layout flex direction="column">
      <Layout flex justifyContent="space-between">
        <Button icon={faTrash} iconOnly color="red" onClick={removePhoto} />
        <Button icon={faTimes} iconOnly color="grey" />
      </Layout>
      <img src={src} alt="ua" />
    </Layout>
  </div>
))`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    max-width: 90vw;
    max-height: 90vh;
  }
`;

const UAPhotoCreate = styled(({ className, onChange }) => (
  <label className={className}>
    <input type="file" onChange={onChange} accept="image/*" />
    <div className="ua-photo__overlay"></div>
    <FontAwesomeIcon icon={faPlus} />
  </label>
))`
  height: 50px;
  width: 50px;
  border: 1px solid #333;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  position: relative;
  margin: 0 4px;

  & input {
    display: none;
  }

  & .ua-photo__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    transition: 0.3s;
  }

  & .ua-photo__overlay:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const UAPhotoEmpty = styled(({ className }) => (
  <label className={className}>
    <div className="ua-photo__overlay"></div>
    <Typography>None</Typography>
  </label>
))`
  height: 50px;
  width: 50px;
  border: 1px solid ${colors.grey[5]};
  border-radius: 5px;
  cursor: not-allowed;
  overflow: hidden;
  background-color: ${colors.grey[6]};
  color: ${colors.grey[4]};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  position: relative;
  margin: 0 4px;
`;

const UAPhotosStyled = styled.div`
  font-size: 12px;
  margin: 8px 0;

  & .ua-photos__collection {
    display: flex;
  }
`;

const UAPhotos = ({
  residentId,
  photos = [],
  newPhotos = [],
  addPhoto,
  removeNewUAPhoto,
  removePhoto,
  editable,
}) => {
  const uaPhotos = photos.map((photo) => (
    <UAPhoto key={photo.id} photo={photo} removePhoto={removePhoto} />
  ));

  const newUaPhotos = newPhotos.map((newPhoto, index) => (
    <UAPhotoNew
      key={index}
      photoFile={newPhoto}
      removePhoto={removeNewUAPhoto}
    />
  ));

  return (
    <UAPhotosStyled>
      <label htmlFor="ua-photos">UA Photos</label>
      <div className="ua-photos__collection">
        {uaPhotos}
        {newUaPhotos}
        {editable && <UAPhotoCreate onChange={addPhoto} />}
        {uaPhotos.length === 0 && newUaPhotos.length === 0 && !editable && (
          <UAPhotoEmpty />
        )}
      </div>
    </UAPhotosStyled>
  );
};

UAPhotos.propTypes = {
  residentId: PropTypes.number.isRequired,
  photos: PropTypes.arrayOf(PropTypes.instanceOf(Photo)),
  newPhotos: PropTypes.arrayOf(PropTypes.object),
  addPhoto: PropTypes.func.isRequired,
  removeNewUAPhoto: PropTypes.func.isRequired,
  removePhoto: PropTypes.func.isRequired,
  editable: PropTypes.bool,
};

export default UAPhotos;
