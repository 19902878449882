import React, { useState } from "react";
import styled from "styled-components";
import format from "date-fns/format";
import {
  Layout,
  TableRow,
  TableBody,
  TableHeader,
  TableSelectedSectionButton,
  TableCell,
  TableSearchField,
  Typography,
  LocationFilter,
  TableFilterModal,
} from "./index";
import { useNavigate } from "react-router-dom";

import { Collection } from "../classes/collection";
import { Location } from "../classes/location";
import { Resident } from "../classes/resident";

import { colors } from "../constants";
import {
  faWrench,
  faUser,
  faAlignLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatMoney } from "../utils";

interface RecordProps {
  resident: Resident;
  location?: Location;
  collection: Collection;
}

const Header = () => (
  <>
    <TableCell basis="200px">Name</TableCell>
    <TableCell basis="100px">Date Paid</TableCell>
    <TableCell basis="100px">Collection</TableCell>
    <TableCell basis="43px">Notes</TableCell>
  </>
);

const EmptyView = () => (
  <Typography
    type="h4"
    bold
    align="center"
    spacing="mt-8"
    color={colors.grey[5]}
  >
    No Collections
  </Typography>
);

interface RowProps {
  row: {
    resident?: Resident;
    location?: Location;
    collection?: Collection;
  };
}

const Row = ({ row, ...props }: RowProps) => (
  <TableRow row={row} SelectedSectionElem={SelectedSection} {...props}>
    <TableCell basis="200px" semiBold>
      {row.resident && `${row.resident.lastName}, ${row.resident.firstName}`}
    </TableCell>
    <TableCell basis="100px" color={colors.grey[3]}>
      {row.collection &&
        row.collection.datePaid &&
        format(new Date(row.collection.datePaid), "P")}
    </TableCell>
    <TableCell basis="100px" color={colors.grey[3]}>
      {row.collection && formatMoney(row.collection.amountPaidWithoutCredit())}
    </TableCell>
    <TableCell basis="43px" color={colors.grey[3]}>
      {row.collection && row.collection.notes && (
        <FontAwesomeIcon icon={faAlignLeft} />
      )}
    </TableCell>
  </TableRow>
);

interface SelectedSectionProps {
  row: RecordProps;
  onView: (row: RecordProps) => void;
}

const SelectedSection = ({ row, onView }: SelectedSectionProps) => {
  const navigate = useNavigate();

  const handleResident = () =>
    navigate(`/dashboard/residents/view/${row.resident.id}`);

  const handleFix = () => onView(row);

  return (
    <>
      <TableSelectedSectionButton icon={faUser} onClick={handleResident}>
        Resident
      </TableSelectedSectionButton>
      <TableSelectedSectionButton icon={faWrench} onClick={handleFix}>
        Fix
      </TableSelectedSectionButton>
    </>
  );
};

const RejectedTableStyles = styled.div`
  height: calc(100% - 52px - 45px - 5px);
`;

interface RejectedTableProps {
  rows: RecordProps[];
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  total: number;
  page: number;
  take: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onView: (row: {
    resident?: Resident;
    location?: Location;
    collection?: Collection;
  }) => void;
}

export const RejectedTable = ({
  rows,
  search,
  setSearch,
  total,
  page,
  take,
  setPage,
  onView,
}: RejectedTableProps) => {
  const filterContent = [LocationFilter];

  return (
    <RejectedTableStyles>
      <TableFilterModal filterContent={filterContent} />
      <TableSearchField
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        filterContent={filterContent}
      />
      <TableHeader>
        <Header />
      </TableHeader>
      <TableBody
        rows={rows}
        total={total}
        page={page}
        take={take}
        setPage={setPage}
        RowElem={Row}
        EmptyViewElem={EmptyView}
        onView={onView}
      />
    </RejectedTableStyles>
  );
};
