import React from "react";
import "./App.css";
import Routes from "./routes";

import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CssBaseline from "@mui/material/CssBaseline";

export default function App() {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <CssBaseline />
      <Routes />
    </LocalizationProvider>
  );
}
