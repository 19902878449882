import React from "react";
import isToday from "date-fns/isToday";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import format from "date-fns/format";
import { Layout, Button, Typography } from "./index";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const DateNavigation = styled(({ className, date, onChange }) => {
  const backwardDate = () => onChange(subDays(date, 1));
  const forwardDate = () => onChange(addDays(date, 1));
  return (
    <Layout className={className} flex justifyContent="center" spacing="py-2">
      <Layout
        flex
        className="inner-layout"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          icon={faChevronLeft}
          iconOnly
          onClick={backwardDate}
          disabled={isToday(date)}
        ></Button>
        <Typography type="h5" bold spacing="m-0">
          {format(date, "PPP")}
        </Typography>
        <Button icon={faChevronRight} iconOnly onClick={forwardDate}></Button>
      </Layout>
    </Layout>
  );
})`
  .inner-layout {
    max-width: 300px;
    width: 100%;
  }
`;

export default DateNavigation;
