import React, { useState, useEffect, useCallback } from "react";
import { useCollectionsApi } from "../apis";
import { CollectionModal } from "./CollectionModal";

import { Grid, Button, Typography, List, ListItem } from "@mui/material";

import { colors } from "../constants";
import format from "date-fns/format";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import NotesIcon from "@mui/icons-material/Notes";
import { CollectionConstructorType, Collection } from "../classes/collection";

import {
  CollectionItem,
  COLLECTION_ITEM_TYPES,
  OTHER_CODES,
} from "../classes/collection-item";

import {
  CollectionPayment,
  COLLECTION_PAYMENT_TYPES,
} from "../classes/collection-payment";
import { Resident } from "../classes/resident";
import { formatMoney } from "../utils";

interface CollectionsHistoryHeaderProps {
  resident: Resident;
  collections: { items: Collection[]; total: number; overallBalance: number };
  onAddCollection: (collection: Collection) => void;
}

const CollectionsHistoryHeader = ({
  resident,
  collections,
  onAddCollection,
}: CollectionsHistoryHeaderProps) => {
  const overallBalance = collections.overallBalance;

  const handleAdd = () => {
    const collectionData: {
      residentId: number;
      items: CollectionItem[];
      payments: CollectionPayment[];
    } = {
      residentId: resident.id as number,
      items: [],
      payments: [],
    };

    if (overallBalance > 0) {
      collectionData.payments.push(
        new CollectionPayment({
          type: COLLECTION_PAYMENT_TYPES.CREDIT,
          amount: overallBalance,
        })
      );
    }

    if (overallBalance < 0) {
      collectionData.items.push(
        new CollectionItem({
          text: "Existing Debt",
          price: Math.abs(overallBalance),
          code: OTHER_CODES.EXISTING_DEBT,
          type: COLLECTION_ITEM_TYPES.DEBT,
        })
      );
    } else {
      // Push an empty item, because we'll
      // expect the user to add one anyways.
      collectionData.items.push(new CollectionItem());
    }

    onAddCollection(new Collection(collectionData));
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{ padding: "0 1rem" }}
      wrap="nowrap"
    >
      <div>
        <Typography variant="h6">Collections History</Typography>

        <Typography style={{ color: colors.grey[3] }}>
          Overall Balance: {formatMoney(overallBalance)}
        </Typography>
      </div>

      {resident.id && (
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={handleAdd}
        >
          Add
        </Button>
      )}
      {!resident.id && (
        <Typography style={{ color: colors.grey[3] }}>
          Save Resident first
        </Typography>
      )}
    </Grid>
  );
};

interface CollectionsHistoryListItemProps {
  collection: Collection;
  onClick: (collection: Collection) => void;
}

const CollectionsHistoryListItem = ({
  collection,
  onClick,
}: CollectionsHistoryListItemProps) => {
  return (
    <ListItem button divider onClick={() => onClick(collection)}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container>
            <Typography style={{ width: 80 }}>
              {formatMoney(collection.total())}
            </Typography>

            <Typography style={{ width: 100 }}>
              {collection.datePaid &&
                format(new Date(collection.datePaid), "PP")}
            </Typography>
          </Grid>
        </Grid>

        {(collection.isPending() || collection.isApproved()) &&
          collection.amountBalance() !== 0 && (
            <Grid item>
              <Grid container wrap="nowrap">
                <Typography style={{ width: 50 }}>Balance</Typography>

                <Typography style={{ width: 80, textAlign: "right" }}>
                  {formatMoney(collection.amountBalance())}
                </Typography>
              </Grid>
            </Grid>
          )}

        <Grid item>{collection.notes && <NotesIcon />}</Grid>

        <Grid item>
          <Typography>{collection.status}</Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

const EmptyItem = () => (
  <Grid
    style={{ height: "3rem" }}
    container
    justifyContent="center"
    alignItems="center"
  >
    <Typography variant="h6" style={{ color: colors.grey[5] }}>
      No Collections
    </Typography>
  </Grid>
);

interface CollectionsHistoryProps {
  resident: Resident;
  fetchResident: () => void;
}

const CollectionsHistory = ({
  resident,
  fetchResident,
}: CollectionsHistoryProps) => {
  const { getCollections } = useCollectionsApi();

  const [collections, setCollections] = useState<{
    items: Collection[];
    total: number;
    overallBalance: number;
  }>({
    items: [],
    total: 0,
    overallBalance: 0,
  });
  const [collection, setCollection] = useState<Collection>(new Collection());
  const [active, setActive] = useState(false);

  const fetchCollections = useCallback(async () => {
    const _collections = await getCollections({ residentId: resident.id });
    setCollections(_collections);
  }, [getCollections, resident.id]);

  useEffect(() => {
    if (resident.id) {
      fetchCollections();
    }
  }, [resident, fetchCollections]);

  const handleItemClick = (collection: Collection) => {
    setCollection(collection);
    setActive(true);
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <CollectionsHistoryHeader
        resident={resident}
        collections={collections}
        onAddCollection={handleItemClick}
      />
      {collections.items.length === 0 && <EmptyItem />}

      {collections.items.length > 0 && (
        <List>
          {collections.items.map((c) => (
            <CollectionsHistoryListItem
              key={c.id}
              collection={c}
              onClick={handleItemClick}
            />
          ))}
        </List>
      )}

      <CollectionModal
        active={active}
        closeModal={() => setActive(false)}
        collection={collection}
        collections={collections}
        resident={resident}
        onCallback={() => {
          // ignore "await"
          fetchResident();
          fetchCollections();
        }}
      />
    </div>
  );
};

export default CollectionsHistory;
