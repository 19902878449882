import { getCollection } from "../utils";
import { User } from "../../classes/user";

export const {
  getCollection: getUsers,
  addDocument: addUser,
  editDocument: editUser,
  setDocument: setUser,
  removeDocument: removeUser,
  reducer: UsersReducer,
} = getCollection("users", User);
