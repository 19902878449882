import React, { useContext, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { ACTIVITY_TYPES } from "../classes/activity";
import { MOVED_IN } from "../classes/resident";
import { useLocationsApi } from "../apis/locations";

export const TableFilterContext = React.createContext();
export const useTableFilter = () => useContext(TableFilterContext);
export const TableFilterProvider = TableFilterContext.Provider;

export const ResidentFilter = {
  component: () => {
    const { hasBPA, setHasBpa } = useTableFilter();
    const { hasNoRentDueDate, setHasNoRentDueDate } = useTableFilter();

    return (
      <Accordion key="resident">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Resident</Typography>
            {(hasBPA || hasNoRentDueDate) && (
              <FiberManualRecordIcon fontSize="small" />
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              style={{ height: 48 }}
            >
              <Typography>Has BPA End Date?</Typography>
              <Switch
                checked={hasBPA}
                onChange={(e) => setHasBpa(e.target.checked)}
              />
            </Grid>

            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              style={{ height: 48 }}
            >
              <Typography>Has No Rent Due Date?</Typography>
              <Switch
                checked={hasNoRentDueDate}
                onChange={(e) => setHasNoRentDueDate(e.target.checked)}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  },
  isActive: () => {
    const { hasBPA, hasNoRentDueDate } = useTableFilter();
    return hasBPA || hasNoRentDueDate;
  },
};

export const LocationFilter = {
  component: () => {
    const { getLocations } = useLocationsApi();
    const { locations, setLocations } = useTableFilter();

    const [allLocations, setAllLocations] = useState({ items: [], total: 0 });

    const fetchLocations = useCallback(async () => {
      const _locations = await getLocations();
      setAllLocations(_locations);
    }, [getLocations]);

    useEffect(() => {
      fetchLocations();
    }, [fetchLocations]);

    const handleListItemClick = (value) => {
      const index = locations.indexOf(value);
      let newLocations = [];
      if (index > -1) {
        newLocations = [
          ...locations.slice(0, index),
          ...locations.slice(index + 1),
        ];
        setLocations(newLocations);
      } else {
        newLocations = [...locations, value];
        setLocations(newLocations);
      }
      console.log("locations", newLocations);
      setLocations(newLocations);
      // TODO:(dr 2022/ 2/01)  send user prefs to API, and pull to sync.
      // addDirectlyDangerous(`users/${me.id}/filters/locations`, newLocations);
    };

    const clearLocations = () => {
      setLocations([]);
      // TODO:(dr 2022/ 2/01)  send user prefs to API, and pull to sync.
      // addDirectlyDangerous(`users/${me.id}/filters/locations`, null);
    };

    return (
      <Accordion key="location">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Locations</Typography>
            {locations.length > 0 && <FiberManualRecordIcon fontSize="small" />}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 300, overflow: "scroll" }}>
            <Grid container direction="column">
              <Grid item>
                <Button onClick={clearLocations}>Clear</Button>
              </Grid>
              <Grid>
                <List>
                  {allLocations.items.map(({ id, name }) => (
                    <ListItem
                      key={id}
                      onClick={() => handleListItemClick(id)}
                      dense
                      disableGutters
                    >
                      <Checkbox
                        checked={
                          !!locations.find((location) => location === id)
                        }
                      />
                      <ListItemText primary={name} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  },
  isActive: () => {
    const { locations } = useTableFilter();
    return locations.length > 0;
  },
};

export const ActivityTypeFilter = {
  component: () => {
    const { activityTypes, setActivityTypes } = useTableFilter();

    const options = [
      { text: "Request Move In", value: ACTIVITY_TYPES.REQUEST_MOVE_IN },
      { text: "Moved In", value: ACTIVITY_TYPES.MOVED_IN },
      { text: "Request Move Out", value: ACTIVITY_TYPES.REQUEST_MOVE_OUT },
      { text: "Moved Out", value: ACTIVITY_TYPES.MOVED_OUT },
      { text: "Location Change", value: ACTIVITY_TYPES.LOCATION_CHANGE },
      { text: "Payment Submitted", value: ACTIVITY_TYPES.PAYMENT_SUBMITTED },
      { text: "Payment Rejected", value: ACTIVITY_TYPES.PAYMENT_REJECTED },
      { text: "Payment Approved", value: ACTIVITY_TYPES.PAYMENT_APPROVED },
    ];

    const handleListItemClick = (value) => {
      const index = activityTypes.indexOf(value);
      let newActivityTypes = [];
      if (index > -1) {
        newActivityTypes = [
          ...activityTypes.slice(0, index),
          ...activityTypes.slice(index + 1),
        ];
        setActivityTypes(newActivityTypes);
      } else {
        newActivityTypes = [...activityTypes, value];
        setActivityTypes(newActivityTypes);
      }
      console.log("activityTypes", newActivityTypes);
      setActivityTypes(newActivityTypes);
    };

    const clearActivityTypes = () => {
      setActivityTypes([]);
    };

    return (
      <Accordion key="activityType">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Activity Types</Typography>
            {activityTypes.length > 0 && (
              <FiberManualRecordIcon fontSize="small" />
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 300, overflow: "scroll" }}>
            <Grid container direction="column">
              <Grid item>
                <Button onClick={clearActivityTypes}>Clear</Button>
              </Grid>
              <Grid>
                <List>
                  {options.map(({ text, value }) => (
                    <ListItem
                      key={value}
                      onClick={() => handleListItemClick(value)}
                      dense
                      disableGutters
                    >
                      <Checkbox
                        checked={
                          !!activityTypes.find(
                            (activityType) => activityType === value
                          )
                        }
                      />
                      <ListItemText primary={text} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  },
  isActive: () => {
    const { activityTypes } = useTableFilter();
    return activityTypes.length > 0;
  },
};

export const ChecksOnlyFilter = {
  component: () => {
    const { checksOnly, setChecksOnly } = useTableFilter();

    return (
      <Accordion key="checksOnly">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Audit</Typography>
            {checksOnly && <FiberManualRecordIcon fontSize="small" />}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography>Check Only</Typography>
            <Switch
              checked={checksOnly}
              onChange={(e) => setChecksOnly(e.target.checked)}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  },

  isActive: () => {
    const { checksOnly } = useTableFilter();
    return checksOnly;
  },
};

export const TableFilterModal = ({ filterContent }) => {
  const { filterModalActive, setFilterModalActive } = useTableFilter();
  return (
    <Dialog
      open={filterModalActive}
      onClose={() => setFilterModalActive(false)}
    >
      <DialogTitle>Filter</DialogTitle>
      <DialogContent>{filterContent.map((c) => c.component())}</DialogContent>
    </Dialog>
  );
};

TableFilterModal.propTypes = {
  filterContent: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const useLocalStorageState = (key, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      console.log(error);
      return defaultValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

export const TableFilter = ({ children }) => {
  const [filterModalActive, setFilterModalActive] = useState(false);

  const [status, setStatus] = useState(MOVED_IN);
  const [hasBPA, setHasBpa] = useState();
  const [hasNoRentDueDate, setHasNoRentDueDate] = useState();

  const [locations, setLocations] = useLocalStorageState(
    "filter.locations",
    []
  );
  const [activityTypes, setActivityTypes] = useLocalStorageState(
    "filter.activityTypes",
    []
  );

  const [checksOnly, setChecksOnly] = useState();

  return (
    <TableFilterProvider
      value={{
        filterModalActive,
        setFilterModalActive,

        status,
        setStatus,

        hasBPA,
        setHasBpa,

        hasNoRentDueDate,
        setHasNoRentDueDate,

        locations,
        setLocations,

        activityTypes,
        setActivityTypes,

        checksOnly,
        setChecksOnly,
      }}
    >
      {children}
    </TableFilterProvider>
  );
};

TableFilter.propTypes = {
  children: PropTypes.node.isRequired,
};
