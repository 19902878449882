import React, { useState, useCallback, useEffect } from "react";
import ManagerForm from "../../../components/ManagerForm";
import { useUsersApi } from "../../../apis";
import { useParams, useNavigate } from "react-router-dom";
import { User } from "../../../classes";

export default function EditManager() {
  const { getUser, updateUser, deleteUser } = useUsersApi();
  const { id } = useParams();
  const navigate = useNavigate();

  const [manager, setManager] = useState(new User());

  const fetchManager = useCallback(async () => {
    const manager = await getUser(id);
    setManager(manager);
  }, [id, getUser]);

  useEffect(() => {
    fetchManager();
  }, [fetchManager]);

  const onSave = async () => {
    try {
      await updateUser(id, manager);
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const onDelete = async () => {
    try {
      if (window.confirm("Are you sure you want to delete this manager?")) {
        await deleteUser(id);
        navigate(-1);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ManagerForm
      title="Edit Manager"
      manager={manager}
      setManager={setManager}
      onSave={onSave}
      onDelete={onDelete}
    />
  );
}
