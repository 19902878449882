import { useCallback } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

async function getSessionTokens() {
  let session = null;
  try {
    session = await Auth.currentSession();
    if (!session) throw new Error("No session");

    const idToken = session.getIdToken().getJwtToken();
    const accessToken = session.getAccessToken().getJwtToken();
    const expiresAt = session.getAccessToken().getExpiration();

    return { idToken, accessToken, expiresAt };
  } catch (e) {
    console.log(e);
    throw new Error("Could not get session tokens");
  }
}

const baseUrl = process.env.REACT_APP_API_URL;

const baseHeaders = {
  "Content-Type": "application/json",
};

export function useApi() {
  const navigate = useNavigate();
  const fetchData = useCallback(async (url, method, headers, body) => {
    const sessionTokens = await getSessionTokens();

    const response = await fetch(`${baseUrl}/${url}`, {
      method,
      headers: {
        Authorization: `Bearer ${sessionTokens.idToken}`,
        ...headers,
      },
      body,
    });

    // If the response is not ok, throw an error
    if (!response.ok) {
      // If the response is unauthorized, logout the user
      if (response.status === 401) {
        navigate("/login");
        return;
      }
      const error = await response.json();
      throw new Error(error.message);
    }

    const data = await response.json();
    return data;
  }, []);

  const fetchGET = useCallback(
    (url) => fetchData(url, "GET", baseHeaders, undefined),
    [fetchData]
  );

  const fetchGETList = useCallback(
    (url, queryParams = {}) => {
      const searchParams = new URLSearchParams();

      Object.keys(queryParams).forEach((key) => {
        if (Array.isArray(queryParams[key])) {
          queryParams[key].forEach((value: string) => {
            searchParams.append(key, value);
          });
        } else {
          searchParams.append(key, queryParams[key]);
        }
      });

      return fetchGET(`${url}?${searchParams.toString()}`);
    },
    [fetchGET]
  );

  const fetchPOST = useCallback(
    (url, body = {}) =>
      fetchData(url, "POST", baseHeaders, JSON.stringify(body)),
    [fetchData]
  );
  const fetchPOSTMultiple = useCallback(
    (url, body) => fetchData(url, "POST", null, body),
    [fetchData]
  );
  const fetchPUT = useCallback(
    (url, body = {}) =>
      fetchData(url, "PUT", baseHeaders, JSON.stringify(body)),
    [fetchData]
  );
  const fetchPUTMultiple = useCallback(
    (url, body) => fetchData(url, "PUT", null, body),
    [fetchData]
  );
  const fetchDELETE = useCallback(
    (url) => fetchData(url, "DELETE", baseHeaders, undefined),
    [fetchData]
  );

  return {
    fetchGET,
    fetchGETList,
    fetchPOST,
    fetchPOSTMultiple,
    fetchPUT,
    fetchPUTMultiple,
    fetchDELETE,
  };
}

export interface GetListInputType {
  skip?: number;
  take?: number;
  orderBy?: string;
}

export interface GetListOutputType<T> {
  items: T[];
  total: number;
}
