import React from "react";
import ResidentsFormPage from "../../../components/ResidentsFormPage";
import { useParams } from "react-router-dom";
import { Resident } from "../../../classes";
import { useResidentsApi } from "../../../apis";

export default function RequestMoveOut() {
  const { id: residentId } = useParams();
  const { updateResident } = useResidentsApi();

  const onSave = async (resident: Resident) =>
    updateResident(residentId, resident.prepareForApi());

  return (
    <ResidentsFormPage
      title="Req Move-out"
      status="REQUEST_MOVE_OUT"
      residentId={Number(residentId)}
      onSave={onSave}
      saveButtonText="Req Move-out"
    />
  );
}
