import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { User } from "./user";

export interface LocationConstructorType extends DatabaseObjectConstructorType {
  name: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  phoneNumber: string;
  gender: string;
  code: string;
  houseManagers: User[];
}

export class Location extends DatabaseObject {
  name = "";
  address = "";
  city = "";
  state = "";
  zipcode = "";
  phoneNumber = "";
  gender = "";
  code = "";
  houseManagers: User[] = [];

  constructor(data?: LocationConstructorType) {
    super(data);

    if (!data) return;

    if (data.name) this.name = data.name;
    if (data.address) this.address = data.address;
    if (data.city) this.city = data.city;
    if (data.state) this.state = data.state;
    if (data.zipcode) this.zipcode = data.zipcode;
    if (data.phoneNumber) this.phoneNumber = data.phoneNumber;
    if (data.gender) this.gender = data.gender;
    if (data.code) this.code = data.code;
    if (data.houseManagers) this.houseManagers = data.houseManagers;
  }

  clone() {
    return new Location({ ...this });
  }

  toString() {
    return (
      `Location` +
      `${super.toString()}` +
      `Name:                  ${this.name}\n` +
      `Address:               ${this.address}\n` +
      `City:                  ${this.city}\n` +
      `State:                 ${this.state}\n` +
      `Zipcode:               ${this.zipcode}\n` +
      `Phone Number:          ${this.phoneNumber}\n` +
      `Gender:                ${this.gender}\n` +
      `Code:                  ${this.code}\n`
      // `House Managers:        ${this.houseManagers}\n` +
    );
  }

  addHouseManager(houseManager: User) {
    this.houseManagers.push(houseManager);
  }
  removeHousemanagerIndex(index: number) {
    this.houseManagers.splice(index, 1);
  }
}
