import React, { useState } from "react";
import LocationForm from "../../../components/LocationForm";
import { useNavigate } from "react-router-dom";
import { Location } from "../../../classes";
import { useLocationsApi } from "../../../apis";

export default function AddLocation() {
  const { addLocation } = useLocationsApi();
  const navigate = useNavigate();
  const [location, setLocation] = useState(
    new Location({
      name: "",
      address: "",
      city: "",
      state: "ID",
      zipcode: "",
      phoneNumber: "",
      gender: "",
      code: "",
      houseManagers: [],
    })
  );

  const onSave = async () => {
    try {
      // Go back to whichever page they got there from.
      await addLocation(location);
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <LocationForm
      title="Add Location"
      location={location}
      setLocation={setLocation}
      onSave={onSave}
    />
  );
}
