import { useCallback } from "react";
import { useApi, GetListInputType, GetListOutputType } from "../hooks";
import { Resident } from "../classes/resident";

export function useResidentsApi() {
  const {
    fetchGETList,
    fetchGET,
    fetchPOST,
    fetchPOSTMultiple,
    fetchPUT,
    fetchDELETE,
  } = useApi();

  const getResident = useCallback(
    (id) => fetchGET(`residents/${id}`),
    [fetchGET]
  );

  const getResidents = useCallback(
    (queryParams) => fetchGETList("residents", queryParams),
    [fetchGETList]
  );

  const addResident = useCallback(
    (data) => fetchPOST("residents", data),
    [fetchPOST]
  );

  const updateResident = useCallback(
    (id, data) => fetchPUT(`residents/${id}`, data),
    [fetchPUT]
  );

  const deleteResident = useCallback(
    (id) => fetchDELETE(`residents/${id}`),
    [fetchDELETE]
  );

  const getResidentPhotos = useCallback(
    (id) => fetchGET(`residents/${id}/photos`),
    [fetchGET]
  );

  const addResidentPhoto = useCallback(
    (id, data) => fetchPOSTMultiple(`residents/${id}/photos`, data),
    [fetchPOST]
  );

  return {
    getResident,
    getResidents,
    addResident,
    updateResident,
    deleteResident,
    getResidentPhotos,
    addResidentPhoto,
  };
}

export interface GetResidentsInputType extends GetListInputType {
  statuses?: string[] | string;
  search?: string;
  locations?: number | number[];
  rentDueDate__eq?: string;
  rentDueDate__lt?: string;
  hasBPA?: boolean;
  hasNoRentDueDate?: boolean;
}

export type GetResidentsOutputType = GetListOutputType<Resident>;
