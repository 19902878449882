import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";

export interface CollectionItemConstructorType
  extends DatabaseObjectConstructorType {
  text?: string;
  price?: number;
  code?: string;
  type?: string;
  rentDueDate?: Date | string | null;
}

export class CollectionItem extends DatabaseObject {
  text = "";
  price = 0;
  code = "";
  type = "";

  rentDueDate?: Date | null = null;

  constructor(data?: CollectionItemConstructorType) {
    super(data);

    if (!data) return;

    if (data.text) this.text = data.text;
    if (data.price) this.price = data.price;
    if (data.code) this.code = data.code;
    if (data.type) this.type = data.type;

    if (data.rentDueDate) this.rentDueDate = new Date(data.rentDueDate);
  }

  toString() {
    return (
      `Text:                  ${this.text}\n` +
      `Price:                 ${this.price}\n` +
      `Code:                  ${this.code}\n` +
      `Type:                  ${this.type}\n` +
      `Rent Due Date:         ${this.rentDueDate}`
    );
  }

  isProduct() {
    return this.type === COLLECTION_ITEM_TYPES.PRODUCT;
  }

  isCustomProduct() {
    return (
      this.type === COLLECTION_ITEM_TYPES.PRODUCT &&
      this.code === PRODUCT_CODES.CUSTOM
    );
  }

  isOther() {
    return this.type === COLLECTION_ITEM_TYPES.OTHER;
  }

  isDebt() {
    return this.type === COLLECTION_ITEM_TYPES.DEBT;
  }

  isPrepayment() {
    return this.type === COLLECTION_ITEM_TYPES.PREPAYMENT;
  }

  static makeFromOption(option: {
    text: string;
    price: number;
    value: string;
    type: string;
  }) {
    return new CollectionItem({
      text: option.text,
      price: option.price,
      code: option.value,
      type: option.type,
    });
  }

  static numberToArray(number: number) {
    return `${number}`.split("");
  }

  static arrayToNumber(array: string[]) {
    if (array.length === 0) return 0;
    return parseInt(array.join(""));
  }
}

export const COLLECTION_ITEM_TYPES = {
  ADDON: "ADDON",
  PRODUCT: "PRODUCT",
  OTHER: "OTHER",
  PREPAYMENT: "PREPAYMENT",
  DEBT: "DEBT",
};

export const ADDON_CODES = {
  UA__1000: "UA__1000",
  UA__2000: "UA__2000",
  LATE_FEE__2500: "LATE_FEE__2500",
  BPA_LATE_FEE__500: "BPA_LATE_FEE__500",
  PROCESSING_FEE__5000: "PROCESSING_FEE__5000",
};

export const PRODUCT_CODES = {
  MONTH_1__41000: "MONTH_1__41000",
  MONTH_1__50000: "MONTH_1__50000",
  MONTH_1__8000: "MONTH_1__8000",
  MONTH_1__75000__GEO: "MONTH_1__75000__GEO",
  MONTH_1__95000__GEO: "MONTH_1__95000__GEO",
  WEEK_1__11500: "WEEK_1__11500",
  WEEK_1__12500: "WEEK_1__12500",
  WEEK_2__21000: "WEEK_2__21000",
  WEEK_2__25000: "WEEK_2__25000",
  DAY_1__2000: "DAY_1__2000",
  DAY_2__4000: "DAY_2__4000",
  DAY_3__6000: "DAY_3__6000",
  DAY_4__8000: "DAY_4__8000",
  STORAGE_HALF_BAY_7500: "STORAGE_HALF_BAY_7500",
  STORAGE_FULL_BAY_10000: "STORAGE_FULL_BAY_10000",
  CUSTOM: "CUSTOM",
};

export const OTHER_CODES = {
  EXISTING_DEBT: "EXISTING_DEBT",
  PARTIAL_PREPAYMENT: "PARTIAL_PREPAYMENT",
  PARTIAL_PREPAYMENT__GEO: "PARTIAL_PREPAYMENT__GEO",
  OTHER: "OTHER",
};

export const ADDON_OPTIONS = [
  {
    text: "UA - $10",
    value: ADDON_CODES.UA__1000,
    price: 1000,
    type: COLLECTION_ITEM_TYPES.ADDON,
  },
  {
    text: "UA - $20",
    value: ADDON_CODES.UA__2000,
    price: 2000,
    type: COLLECTION_ITEM_TYPES.ADDON,
  },
  {
    text: "Late Fee - $25",
    value: ADDON_CODES.LATE_FEE__2500,
    price: 2500,
    type: COLLECTION_ITEM_TYPES.ADDON,
  },
  {
    text: "BPA Late Fee - $5",
    value: ADDON_CODES.BPA_LATE_FEE__500,
    price: 500,
    type: COLLECTION_ITEM_TYPES.ADDON,
  },
  {
    text: "Processing Fee - $50",
    value: ADDON_CODES.PROCESSING_FEE__5000,
    price: 5000,
    type: COLLECTION_ITEM_TYPES.ADDON,
  },
];

export const PRODUCT_OPTIONS = [
  {
    text: "1 Month - $410",
    value: PRODUCT_CODES.MONTH_1__41000,
    price: 41000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "1 Month - $500 (Riverstone)",
    value: PRODUCT_CODES.MONTH_1__50000,
    price: 50000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "1 Month - $80",
    value: PRODUCT_CODES.MONTH_1__8000,
    price: 8000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "1 Month - $750 (GEO)",
    value: PRODUCT_CODES.MONTH_1__75000__GEO,
    price: 75000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "1 Month - $950 (GEO)",
    value: PRODUCT_CODES.MONTH_1__95000__GEO,
    price: 95000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "1 Week - $115",
    value: PRODUCT_CODES.WEEK_1__11500,
    price: 11500,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "1 Week - $125 (Riverstone)",
    value: PRODUCT_CODES.WEEK_1__12500,
    price: 12500,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "2 Week - $210",
    value: PRODUCT_CODES.WEEK_2__21000,
    price: 21000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "2 Week - $250 (Riverstone)",
    value: PRODUCT_CODES.WEEK_2__25000,
    price: 25000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "1 Day - $20",
    value: PRODUCT_CODES.DAY_1__2000,
    price: 2000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "2 Day - $40",
    value: PRODUCT_CODES.DAY_2__4000,
    price: 4000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "3 Day - $60",
    value: PRODUCT_CODES.DAY_3__6000,
    price: 6000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "4 Day - $80",
    value: PRODUCT_CODES.DAY_4__8000,
    price: 8000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "Storage Half Bay - $75",
    value: PRODUCT_CODES.STORAGE_HALF_BAY_7500,
    price: 7500,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "Storage Full Bay - $100",
    value: PRODUCT_CODES.STORAGE_FULL_BAY_10000,
    price: 10000,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
  },
  {
    text: "Custom Price & Date",
    value: PRODUCT_CODES.CUSTOM,
    type: COLLECTION_ITEM_TYPES.PRODUCT,
    price: 0,
  },
];

export const OTHER_OPTIONS = [
  {
    text: "Other",
    value: OTHER_CODES.OTHER,
    type: COLLECTION_ITEM_TYPES.OTHER,
    price: 0,
  },
];

export const PARTIAL_PREPAYMENT_OPTIONS = [
  {
    text: "Partial Prepayment",
    value: OTHER_CODES.PARTIAL_PREPAYMENT,
    type: COLLECTION_ITEM_TYPES.PREPAYMENT,
    price: 0,
  },
  {
    text: "Partial Prepayment (Geo)",
    value: OTHER_CODES.PARTIAL_PREPAYMENT__GEO,
    type: COLLECTION_ITEM_TYPES.PREPAYMENT,
    price: 0,
  },
];

export const DEBT_OPTIONS = [
  {
    text: "Existing Debt",
    value: OTHER_CODES.EXISTING_DEBT,
    type: COLLECTION_ITEM_TYPES.DEBT,
    price: 0,
  },
];

export const COLLECTION_OPTIONS = {
  Addons: ADDON_OPTIONS,
  Products: PRODUCT_OPTIONS,
  "Partial Prepayments": PARTIAL_PREPAYMENT_OPTIONS,
  Other: OTHER_OPTIONS,
};

export const COLLECTION_OPTIONS_NO_PRODUCTS = {
  Addons: ADDON_OPTIONS,
  Other: OTHER_OPTIONS,
};

export function findOption(code: string) {
  let option = ADDON_OPTIONS.find((o) => o.value === code);
  if (option) return option;

  option = PRODUCT_OPTIONS.find((o) => o.value === code);
  if (option) return option;

  option = OTHER_OPTIONS.find((o) => o.value === code);
  if (option) return option;

  option = DEBT_OPTIONS.find((o) => o.value === code);
  if (option) return option;

  option = PARTIAL_PREPAYMENT_OPTIONS.find((o) => o.value === code);
  if (option) return option;

  return {
    text: "",
    value: "",
    type: "",
    price: 0,
  };
}
