import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material";
import { FormHeader } from "../components";
import { STATE_ABBREVIATIONS } from "../constants";
import { Location } from "../classes/location";
import { User } from "../classes/user";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { useUsersApi } from "../apis";

interface LocationFormProps {
  title: string;
  location: Location;
  setLocation: React.Dispatch<React.SetStateAction<Location>>;
  onSave: () => void;
  onDelete?: () => void;
}

export default function LocationForm({
  title,
  location,
  setLocation,
  onSave,
  onDelete,
}: LocationFormProps) {
  const { getUsers } = useUsersApi();

  const [users, setUsers] = useState<{ items: User[]; total: number }>({
    items: [],
    total: 0,
  });

  const fetchUsers = useCallback(async () => {
    const _users = await getUsers();
    setUsers(_users);
  }, [getUsers]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    const newLocation = new Location({
      ...location,
      [e.target.name]: value,
    });

    setLocation(newLocation);
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const newLocation = new Location({
      ...location,
      [e.target.name]: e.target.value,
    });

    setLocation(newLocation);
  };

  const handleHouseManagerChange = (e: SelectChangeEvent<number[]>) => {
    console.log(e.target.value);
    const newLocation = new Location({
      ...location,
      [e.target.name]: (e.target.value as number[]).map((id: number) => ({
        id,
      })),
    });

    setLocation(newLocation);
  };

  const houseManagers = users.items.map((user) => ({
    text: `${user.firstName} ${user.lastName}`,
    value: user.id,
  }));

  return (
    <>
      <FormHeader
        title={title}
        primaryButton={{
          onClick: onSave,
          text: "Save",
          startIcon: <SaveIcon />,
        }}
      />
      <div className="content">
        <form>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                label="Name"
                name="name"
                value={location.name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item>
              <TextField
                label="Address"
                name="address"
                value={location.address}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item>
              <TextField
                label="City"
                name="city"
                value={location.city}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="state-label">State</InputLabel>
                <Select
                  labelId="state-label"
                  name="state"
                  variant="filled"
                  value={location.state}
                  input={<OutlinedInput label="State" />}
                  onChange={handleSelectChange}
                >
                  {STATE_ABBREVIATIONS.map(({ text, value }) => (
                    <MenuItem key={value} value={value}>
                      {text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <TextField
                label="ZIP Code"
                name="zipcode"
                value={location.zipcode}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item>
              <TextField
                label="Location Phone Number"
                name="phoneNumber"
                value={location.phoneNumber}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  labelId="gender-label"
                  name="gender"
                  variant="filled"
                  value={location.gender}
                  input={<OutlinedInput label="Gender" />}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="coed">Coed</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <TextField
                label="Short Name"
                name="code"
                value={location.code}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="house-managers-label">
                  House Managers
                </InputLabel>
                <Select
                  labelId="house-managers-label"
                  name="houseManagers"
                  variant="filled"
                  multiple
                  value={location.houseManagers.map((user) => user.id || 0)}
                  input={<OutlinedInput label="House Managers" />}
                  onChange={handleHouseManagerChange}
                >
                  {houseManagers.map(({ text, value }) => (
                    <MenuItem key={value} value={value}>
                      {text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>

        {location.isPersisted() && (
          <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
            <Button color="error" onClick={onDelete} startIcon={<DeleteIcon />}>
              Delete Location
            </Button>
          </Grid>
        )}
      </div>
    </>
  );
}
