import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { TextField, useTableFilter } from "./index";

// import { VariableSizeList as List } from "react-window";
// import AutoSizer from "react-virtualized-auto-sizer";
import { colors, roundCorners } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  Badge,
} from "@mui/material";

import { Collection } from "../classes/collection";
import { Location } from "../classes/location";
import { Resident } from "../classes/resident";
import { User } from "../classes/user";
import { Activity } from "../classes/activity";

const TableCell = styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;

  min-height: 22px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  flex-grow: 0;
  flex-shrink: 1;
  ${({ basis }) =>
    basis &&
    css`
      flex-basis: ${basis};
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ semiBold }) =>
    semiBold &&
    css`
      font-weight: 600;
    `}
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #111111;
`;

const TableSelectedSectionButton = styled(
  ({ className, icon, children, onClick }) => (
    <Box sx={{ mx: 1, p: 1 }} className={className} onClick={onClick}>
      <Grid container justifyContent="center">
        <FontAwesomeIcon icon={icon} />
      </Grid>
      <Typography>{children}</Typography>
    </Box>
  )
)`
  color: ${colors.font.light};
  font-size: 0.8rem;
  border: 1px solid ${colors.white};
  border-radius: ${roundCorners[0]};
  cursor: pointer;
  min-width: 3rem;

  &:hover {
    background-color: ${colors.green[2]};
  }
`;

const SelectedSection = styled(
  ({ className, row, onView, SelectedSectionElem, ...props }) => {
    console.log("row", row);

    return (
      <Grid
        container
        wrap="nowrap"
        justifyContent="center"
        alignItems="center"
        className={className}
        onClick={(e) => e.stopPropagation()}
      >
        <SelectedSectionElem row={row} onView={onView} {...props} />
      </Grid>
    );
  }
)`
  background-color: ${colors.green[3]};
  height: 60px;
`;

const TableRow = styled(
  ({
    className,
    row,
    selected,
    SelectedSectionElem,
    children,
    onClick,
    ...props
  }) => (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={className}
      onClick={onClick}
    >
      <Grid container justifyContent="space-between" wrap="wrap" height="66px">
        {children}
      </Grid>
      {selected && (
        <SelectedSection
          row={row}
          SelectedSectionElem={SelectedSectionElem}
          {...props}
        />
      )}
    </Grid>
  )
)`
  border-bottom: 1px solid #ddd;

  ${({ selected }) =>
    selected &&
    css`
      box-shadow: inset 0 0 20px ${colors.green[3]};
    `}

  transition: 0.1s;
  cursor: pointer;
  &:hover {
    background-color: ${colors.grey[6]};
  }
`;

interface RecordProps {
  resident?: Resident;
  location?: Location;
  collection?: Collection;
  user?: User;
  activity?: Activity;
}

interface RowElemProps {
  row: RecordProps;
  selected: boolean;
  onClick: () => void;
}

interface TableBodyProps {
  rows: RecordProps[];
  total: number;
  page: number;
  take: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  RowElem: ({ row }: RowElemProps) => JSX.Element;
  EmptyViewElem: () => JSX.Element;
  onView?: (row: RecordProps) => void;
  onAddCollection?: (resident: Resident, collection: Collection) => void;
  onPaymentContract?: (resident: Resident) => void;
  fetchData?: () => Promise<void>;
}

const TableBody = ({
  rows,
  total,
  page,
  take,
  setPage,
  RowElem,
  EmptyViewElem,
  ...props
}: TableBodyProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const onRowClick = (index: number) => {
    if (index === selectedIndex) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
    }
  };

  if (rows.length === 0 && EmptyViewElem) return <EmptyViewElem />;

  // use page and take to calculate if next button should be disabled
  const nextDisabled = (page + 1) * take >= total;

  // use page and take to calculate if prev button should be disabled
  const prevDisabled = page === 0;

  return (
    <>
      {rows.map((row, index) => (
        <RowElem
          key={index}
          row={row}
          selected={index === selectedIndex}
          onClick={() => onRowClick(index)}
          {...props}
        />
      ))}

      {rows.length < total && !(prevDisabled && nextDisabled) && (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button onClick={() => setPage(page - 1)} disabled={prevDisabled}>
              prev
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => setPage(page + 1)} disabled={nextDisabled}>
              next
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

interface FilterProps {
  component: () => JSX.Element;
  isActive: () => boolean;
}

interface TableFilterButtonProps {
  filterContent: FilterProps[];
}

const TableFilterButton = ({ filterContent }: TableFilterButtonProps) => {
  const { setFilterModalActive } = useTableFilter();

  const filterClick = () => setFilterModalActive(true);

  const isFilterActive = filterContent.reduce(
    (prev, curr) => prev || curr.isActive(),
    false
  );

  return (
    <IconButton onClick={filterClick}>
      <Badge color="secondary" variant="dot" invisible={!isFilterActive}>
        <FilterListIcon />
      </Badge>
    </IconButton>
  );
};

const TableSearchFieldStyles = styled(({ className, children }) => (
  <Grid container alignItems="center" className={className}>
    {children}
  </Grid>
))`
  background-color: ${colors.grey[6]};
  .text-field {
    flex: 1 1 auto;
  }
`;

interface TableSearchFieldProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filterContent?: FilterProps[];
}

const TableSearchField = ({
  value,
  onChange,
  filterContent,
}: TableSearchFieldProps) => {
  return (
    <TableSearchFieldStyles>
      <Grid sx={{ paddingLeft: 1 }}>
        <FontAwesomeIcon icon={faSearch} />
      </Grid>

      <Grid sx={{ paddingX: 1 }} className="text-field">
        <TextField value={value} onChange={onChange} clearable />
      </Grid>

      {filterContent && filterContent.length > 0 && (
        <TableFilterButton filterContent={filterContent} />
      )}
    </TableSearchFieldStyles>
  );
};

export {
  TableBody,
  TableRow,
  TableCell,
  TableHeader,
  TableSelectedSectionButton,
  TableSearchField,
};
