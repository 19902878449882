export const breakpoints = {
  xs: "0px",
  xsMax: "480px",
  sm: "481px",
  smMax: "768px",
  md: "769px",
  mdMax: "1024px",
  lg: "1025px",
  lgMax: "1439px",
  xl: "1440px",
};

export const roundCorners = {
  0: "0.25rem",
};

export const lineHeight = "1.5em";

export const fonts = {
  bodyFontFamily: '"Nunito", sans-serif',
  headerFontFamily: '"Nunito", sans-serif',
};

export const fontSizes = {
  body: "1rem",
  bodyLarge: "1.125rem",
  small: "0.85rem",
  h1: "3rem",
  h2: "1.75rem",
  h3: "1.6rem",
  h4: "1.375rem",
  h5: "1.125rem",
  h6: "1rem",
};

export const fontWeights = {
  bold: "600",
  semiBold: "500",
  regular: "400",
};

export const spacings = {
  0: 0,
  1: 0.35,
  2: 0.5,
  3: 0.75,
  4: 1,
  5: 1.5,
  6: 2.125,
  7: 3,
  8: 3.75,
  9: 6,
};

export const colors = {
  white: "#FFFFFF",

  font: {
    light: "#FFFFFF",
    dark: "hsl(42, 13%, 18%)",
  },

  overlay: "hsla(41, 15%, 28%, 0.5)",

  grey: {
    0: "hsl(42, 13%, 18%)",
    1: "hsl(41, 15%, 28%)",
    2: "hsl(40, 12%, 43%)",
    3: "hsl(39, 12%, 58%)",
    4: "hsl(39, 16%, 76%)",
    5: "hsl(39, 21%, 88%)",
    6: "hsl(38, 25%, 93%)",
  },

  green: {
    0: "hsl(75, 39%, 41%)",
    1: "hsl(75, 41%, 46%)",
    2: "hsl(75, 43%, 52%)",
    3: "hsl(75, 45%, 58%)",
    4: "hsl(75, 47%, 62%)",
    5: "hsl(75, 49%, 66%)",
    6: "hsl(75, 51%, 69%)",
  },

  red: {
    0: "hsl(357, 56%, 16%)",
    1: "hsl(357, 59%, 27%)",
    2: "hsl(357, 62%, 38%)",
    3: "hsl(357, 65%, 50%)",
    4: "hsl(357, 68%, 62%)",
    5: "hsl(357, 71%, 74%)",
    6: "hsl(357, 74%, 86%)",
  },

  blue: {
    0: "hsl(231, 60%, 17%)",
    1: "hsl(231, 54%, 28%)",
    2: "hsl(231, 51%, 37%)",
    3: "hsl(231, 48%, 48%)",
    4: "hsl(231, 48%, 63%)",
    5: "hsl(231, 61%, 74%)",
    6: "hsl(231, 64%, 81%)",
  },

  yellow: {
    0: "hsl(50, 82%, 26%)",
    1: "hsl(50, 85%, 38%)",
    2: "hsl(50, 88%, 44%)",
    3: "hsl(50, 91%, 65%)",
    4: "hsl(50, 93%, 68%)",
    5: "hsl(50, 95%, 72%)",
    6: "hsl(50, 97%, 84%)",
  },
};

export const shadows = {
  0: "box-shadow: none",
  1: "0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.20)",
  2: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.20)",
  3: "0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.20)",
  4: "0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.20)",
  5: "0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.20)",
  6: "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.20)",
  7: "0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.20)",
  8: "0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.20)",
  9: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.20)",
  10: "0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.20)",
};

export const STATE_ABBREVIATIONS = [
  { text: "ALABAMA", value: "AL" },
  { text: "ALASKA", value: "AK" },
  { text: "ARIZONA", value: "AZ" },
  { text: "ARKANSAS", value: "AR" },
  { text: "CALIFORNIA", value: "CA" },
  { text: "COLORADO", value: "CO" },
  { text: "CONNECTICUT", value: "CT" },
  { text: "DELAWARE", value: "DE" },
  { text: "FLORIDA", value: "FL" },
  { text: "GEORGIA", value: "GA" },
  { text: "HAWAII", value: "HI" },
  { text: "IDAHO", value: "ID" },
  { text: "ILLINOIS", value: "IL" },
  { text: "INDIANA", value: "IN" },
  { text: "IOWA", value: "IA" },
  { text: "KANSAS", value: "KS" },
  { text: "KENTUCKY", value: "KY" },
  { text: "LOUISIANA", value: "LA" },
  { text: "MAINE", value: "ME" },
  { text: "MARYLAND", value: "MD" },
  { text: "MASSACHUSETTS", value: "MA" },
  { text: "MICHIGAN", value: "MI" },
  { text: "MINNESOTA", value: "MN" },
  { text: "MISSISSIPPI", value: "MS" },
  { text: "MISSOURI", value: "MO" },
  { text: "MONTANA", value: "MT" },
  { text: "NEBRASKA", value: "NE" },
  { text: "NEVADA", value: "NV" },
  { text: "NEW HAMPSHIRE", value: "NH" },
  { text: "NEW JERSEY", value: "NJ" },
  { text: "NEW MEXICO", value: "NM" },
  { text: "NEW YORK", value: "NY" },
  { text: "NORTH CAROLINA", value: "NC" },
  { text: "NORTH DAKOTA", value: "ND" },
  { text: "OHIO", value: "OH" },
  { text: "OKLAHOMA", value: "OK" },
  { text: "OREGON", value: "OR" },
  { text: "PENNSYLVANIA", value: "PA" },
  { text: "RHODE ISLAND", value: "RI" },
  { text: "SOUTH CAROLINA", value: "SC" },
  { text: "SOUTH DAKOTA", value: "SD" },
  { text: "TENNESSEE", value: "TN" },
  { text: "TEXAS", value: "TX" },
  { text: "UTAH", value: "UT" },
  { text: "VERMONT", value: "VT" },
  { text: "VIRGINIA", value: "VA" },
  { text: "WASHINGTON", value: "WA" },
  { text: "WEST VIRGINIA", value: "WV" },
  { text: "WISCONSIN", value: "WI" },
  { text: "WYOMING", value: "WY" },
];
