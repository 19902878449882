import { useCallback } from "react";
import { useApi, GetListInputType, GetListOutputType } from "../hooks";
import { User } from "../classes/user";

export function useUsersApi() {
  const { fetchGETList, fetchGET, fetchPOST, fetchPUT, fetchDELETE } = useApi();

  const getUser = useCallback((id) => fetchGET(`users/${id}`), [fetchGET]);

  const getUsers = useCallback(
    (queryParams?) => fetchGETList("users", queryParams),
    [fetchGETList]
  );

  const addUser = useCallback((data) => fetchPOST("users", data), [fetchPOST]);

  const updateUser = useCallback(
    (id, data) => fetchPUT(`users/${id}`, data),
    [fetchPUT]
  );

  const deleteUser = useCallback(
    (id) => fetchDELETE(`users/${id}`),
    [fetchDELETE]
  );

  return {
    getUser,
    getUsers,
    addUser,
    updateUser,
    deleteUser,
  };
}

export interface GetUsersInputType extends GetListInputType {
  search?: string;
}

export type GetUsersOutputType = GetListOutputType<User>;
