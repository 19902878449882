import firebase from "firebase/compat/app";
import "firebase/compat/database";

export function getCollection(ref, Class) {
  const actions = {
    GET_DATA: `GET_${ref.toUpperCase()}`,
    LOAD_DATA: `LOAD_${ref.toUpperCase()}`,
    ERROR_DATA: `ERROR_${ref.toUpperCase()}`,

    GET_DOCUMENT: `GET_DOCUMENT_${ref.toUpperCase()}`,
    LOAD_DOCUMENT: `LOAD_DOCUMENT_${ref.toUpperCase()}`,
    ERROR_DOCUMENT: `ERROR_DOCUMENT_${ref.toUpperCase()}`,
  };

  async function getCollection(dispatch) {
    dispatch({ type: actions.GET_DATA, payload: null });

    firebase
      .database()
      .ref(ref)
      .on(
        "value",
        (snapshot) => {
          console.log("updated store", ref);
          const collection = snapshot.val();

          const collectionArray = [];
          snapshot.forEach((documentSnapshot) => {
            collectionArray.push(
              new Class({
                ...documentSnapshot.val(),
                key: documentSnapshot.key,
              })
            );
          });

          collectionArray.reverse();

          dispatch({
            type: actions.LOAD_DATA,
            payload: { collection, collectionArray },
          });
        },
        (error) => dispatch({ type: actions.ERROR_DATA, payload: { error } })
      );
  }

  async function getDocument(dispatch, key) {
    dispatch({ type: actions.GET_DOCUMENT, payload: null });

    firebase
      .database()
      .ref(ref)
      .child(key)
      .on(
        "value",
        (snapshot) => {
          console.log("updated store", ref);
          const document = new Class({
            ...snapshot.val(),
            key: snapshot.key,
          });

          dispatch({
            type: actions.LOAD_DOCUMENT,
            payload: { document },
          });
        },
        (error) =>
          dispatch({ type: actions.ERROR_DOCUMENT, payload: { error } })
      );

    return () => {
      firebase.database().ref(ref).child(key).off();
    };
  }

  async function addDocument(data) {
    let dbRef;

    if (data.key) {
      dbRef = firebase.database().ref(`${ref}/${data.key}`);
    } else {
      dbRef = firebase.database().ref(ref).push();
    }

    await dbRef.set(data.forFirebase());
    return dbRef.once("value");
  }

  async function editDocument(data) {
    const dbRef = firebase.database().ref(`${ref}/${data.key}`);
    await dbRef.update(data.forFirebase());
    return dbRef.once("value");
  }

  async function setDocument(data) {
    const dbRef = firebase.database().ref(`${ref}/${data.key}`);
    await dbRef.set(data.forFirebase());
    return dbRef.once("value");
  }

  async function removeDocument(data) {
    return firebase.database().ref(`${ref}/${data.key}`).remove();
  }

  const initialState = {
    collection: {},
    collectionArray: [],
    loading: false,
    error: null,
  };

  const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case actions.GET_DATA:
        return { ...state, loading: true, error: null };

      case actions.LOAD_DATA:
        return {
          ...state,
          collection: payload.collection,
          collectionArray: payload.collectionArray,
          loading: false,
          error: null,
        };

      case actions.ERROR_DATA:
        return { ...state, loading: false, error: payload.error };

      case actions.GET_DOCUMENT:
        return { ...state, loading: true, error: null };

      case actions.LOAD_DOCUMENT:
        return {
          ...state,
          document: payload.document,
          loading: false,
          error: null,
        };

      case actions.ERROR_DOCUMENT:
        return { ...state, loading: false, error: payload.error };

      default:
        return state;
    }
  };

  return {
    actions,
    getCollection,
    getDocument,
    addDocument,
    editDocument,
    setDocument,
    removeDocument,
    reducer,
  };
}

export async function addDirectlyDangerous(ref, data) {
  const dbRef = firebase.database().ref(ref);
  await dbRef.set(data);
  return dbRef.once("value");
}
