import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useActivitiesApi } from "../../../apis/activities";
import { useResidentsApi } from "../../../apis/residents";
import ResidentsForm from "../../../components/ResidentsForm";
import FormHeader from "../../../components/FormHeader";
import { useMe } from "../../../store/me";
import { Activity, Collection, Resident } from "../../../classes";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography, TextField, Grid, Button } from "@mui/material";

import format from "date-fns/format";

const textFieldStyles = {
  width: "100%",
  margin: "0.5rem 0",
};

export default function ActivityView() {
  const { id } = useParams();
  const { getActivity, deleteActivity } = useActivitiesApi();
  const { getResidentPhotos } = useResidentsApi();
  const navigate = useNavigate();
  const { isAdmin } = useMe();

  const [activity, setActivity] = useState<Activity>(new Activity());
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPhotos = useCallback(
    async (residentId) => {
      const _photos = await getResidentPhotos(residentId);
      setPhotos(_photos.items);
    },
    [getResidentPhotos]
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const activity = await getActivity(id);
    await fetchPhotos(activity.residentId);
    setActivity(activity);
    setIsLoading(false);
  }, [id, getActivity]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onDelete = async () => {
    try {
      if (window.confirm("Are you sure you want to delete this activity?")) {
        await deleteActivity(activity.id);
        navigate(-1);
      }
    } catch (e) {
      console.error(e);
    }
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <FormHeader title="View Activity" />
      <Grid container direction="column" spacing={2} sx={{ padding: 2 }}>
        <Grid item>
          <TextField
            style={textFieldStyles}
            label="Action"
            variant="outlined"
            value={activity.type}
            disabled
          />
        </Grid>
        <Grid item>
          <TextField
            style={textFieldStyles}
            label="Date"
            variant="outlined"
            value={format(new Date(activity.createdAt as string), "M/d/yyyy")}
            disabled
          />
        </Grid>
        <Grid item>
          <TextField
            style={textFieldStyles}
            label="Time"
            variant="outlined"
            value={format(new Date(activity.createdAt as string), "h:mm a")}
            disabled
          />
        </Grid>
      </Grid>

      {activity.resident && (
        <>
          <Typography align="center" variant="h6" sx={{ marginTop: 4 }}>
            Resident Info
          </Typography>
          <ResidentsForm
            status="view"
            resident={activity.resident}
            setResident={() => console.log("set resident")}
            photos={photos}
            newPhotos={[]}
            addPhoto={() => console.log("add photo")}
            removePhoto={() => console.log("remove photo")}
            removeNewPhoto={() => console.log("remove new photo")}
            forceDisable
          />
        </>
      )}

      {!activity.resident && (
        <h2 style={{ textAlign: "center", marginTop: 32 }}>
          Unable to get Resident Info
        </h2>
      )}

      {activity.collection && (
        <div style={{ padding: "0 1rem" }}>
          <TextField
            style={{ width: "100%" }}
            label="Collection"
            variant="outlined"
            multiline
            value={new Collection(activity.collection).toString()}
            disabled
            sx={{
              fontFamily: "monospace",
              fontSize: "10px",
            }}
          />
        </div>
      )}

      {isAdmin() && (
        <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
          <Button
            color="error"
            onClick={() => onDelete()}
            startIcon={<DeleteIcon />}
          >
            Delete Activity
          </Button>
        </Grid>
      )}
    </>
  );
}
