import { getCollection } from "../utils";
import { Collection } from "../../classes/collection";

export const {
  getCollection: getCollections,
  addDocument: addCollection,
  editDocument: editCollection,
  removeDocument: removeCollection,
  reducer: CollectionsReducer,
} = getCollection("collections", Collection);
