import React, { useState, useEffect, useCallback } from "react";
import { ActivityTable, useTableFilter } from "../../../components";
import {
  useActivitiesApi,
  GetActivitiesInputType,
  GetActivitiesOutputType,
} from "../../../apis/activities";
import { Activity } from "../../../classes/activity";
import { Resident } from "../../../classes/resident";
import { Location } from "../../../classes/location";

export default function ActivityPage() {
  const { getActivities } = useActivitiesApi();
  const { locations, activityTypes } = useTableFilter();

  const [activities, setActivities] = useState<GetActivitiesOutputType>({
    items: [],
    total: 0,
  });

  const [page, setPage] = useState(0);
  const [take, setTake] = useState(30);

  const [search, setSearch] = useState("");

  const fetchData = useCallback(async () => {
    const customQuery: GetActivitiesInputType = {
      skip: page * take,
      take,
    };

    if (search) {
      customQuery.search = search;
    }

    if (activityTypes.length > 0) {
      customQuery.activityTypes = activityTypes;
    }

    if (locations.length > 0) {
      customQuery.locations = locations;
    }

    const _activities = await getActivities(customQuery);

    setActivities(_activities);
  }, [page, take, locations, activityTypes, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const rows = activities.items.map((activity) => {
    return {
      activity: new Activity(activity),
      resident: new Resident(activity.resident),
      location: new Location(activity.location),
    };
  });

  return (
    <ActivityTable
      rows={rows}
      total={activities.total}
      page={page}
      take={take}
      setPage={setPage}
      search={search}
      setSearch={setSearch}
    />
  );
}
