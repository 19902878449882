import React, { useEffect, useCallback } from "react";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import { useLocationsApi } from "../apis";
import { useMe } from "../store/me";
import Toggle from "./Toggle";
import { Select } from "./Select";
import UAPhotos from "./UAPhotos";
import { Grid, TextField } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/lab";
import {
  REQUEST_MOVE_IN,
  MOVED_IN,
  REQUEST_MOVE_OUT,
  MOVED_OUT,
  VIEW,
  LOCATION_CHANGE,
  PAYMENT_SCHEDULE_OPTIONS,
  RESIDENT_STATUS_OPTIONS,
} from "../classes";
import { Resident } from "../classes/resident";
import { Location } from "../classes/location";
import { Photo } from "../classes/photo";
import parseISO from "date-fns/parseISO";
import { convertUTCToLocal, convertLocalToUTC } from "../utils/date-helpers";
import { handleDateChange } from "../utils/handlers";

interface ResidentsFormProps {
  status: string;
  resident: Resident;
  setResident: React.Dispatch<React.SetStateAction<Resident>>;
  forceDisable?: boolean;
  photos: Photo[];
  newPhotos: File[];
  addPhoto: (e: React.ChangeEvent<HTMLInputElement>) => void;
  removePhoto: (photo: Photo) => void;
  removeNewPhoto: (file: File) => void;
}

export default function ResidentsForm({
  status,
  resident,
  setResident,
  forceDisable,

  photos,
  newPhotos,
  addPhoto,
  removePhoto,
  removeNewPhoto,
}: ResidentsFormProps) {
  const { isAdmin } = useMe();

  const [locationOptions, setLocationOptions] = React.useState();
  const { getLocations } = useLocationsApi();

  const fetchLocations = useCallback(async () => {
    const _locations = await getLocations();
    const _locationOptions = _locations.items.map((l: Location) => ({
      text: l.name,
      value: l.id,
    }));
    setLocationOptions(_locationOptions);
  }, [getLocations]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  const handleChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let value;
      if (e instanceof Date || !e) {
        value = e;
      } else {
        switch (e.target.type) {
          case "checkbox":
            value = e.target.checked;
            break;
          case "number":
            value = parseInt(e.target.value);
            break;
          default:
            value = e.target.value;
        }
      }

      const newResident = new Resident({
        ...resident,
        [key]: value,
      });
      setResident(newResident);
    };

  const handleSelectChange =
    (key: string) => (e: React.ChangeEvent<{ value: unknown }>) => {
      const value = e.target.value;

      const newResident = new Resident({
        ...resident,
        [key]: value,
      });
      setResident(newResident);
    };

  const handleLocationChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newResident = new Resident(resident);

    newResident.locationId = e.target.value
      ? Number(e.target.value)
      : undefined;

    setResident(newResident);
  };

  const disabled = (fieldName: string) => {
    // Disable Override
    if (forceDisable) return true;

    // Notes need to be accessible always.
    if (fieldName === "notes") return false;

    if (fieldName === "contactNumber") return false;

    // On VIEW, we allow everything for Admins.
    if (status === VIEW) {
      if (isAdmin()) {
        return false;
      } else {
        return true;
      }
    }

    switch (fieldName) {
      default:
        return (
          [REQUEST_MOVE_OUT, MOVED_OUT, LOCATION_CHANGE].findIndex(
            (a) => a === status
          ) > -1
        );

      case "locationId":
        return (
          [REQUEST_MOVE_OUT, MOVED_OUT].findIndex((a) => a === status) > -1
        );

      case "status":
        return true;

      case "moveOutNotes":
      case "moveOutDate":
        return (
          [REQUEST_MOVE_IN, MOVED_IN, LOCATION_CHANGE].findIndex(
            (a) => a === status
          ) > -1
        );
    }
  };

  if (!locationOptions) return <></>;

  return (
    <form className="content">
      <Grid container spacing={2}>
        <Grid item container>
          <TextField
            label="First Name"
            name="firstName"
            value={resident.firstName}
            onChange={handleChange("firstName")}
            disabled={disabled("firstName")}
            fullWidth
          />
        </Grid>
        <Grid item container>
          <TextField
            label="Last Name"
            name="lastName"
            value={resident.lastName}
            onChange={handleChange("lastName")}
            disabled={disabled("lastName")}
            fullWidth
          />
        </Grid>
        <Grid item container>
          <Select
            label="Resident Status"
            name="status"
            options={RESIDENT_STATUS_OPTIONS}
            value={resident.status as string}
            onChange={handleSelectChange("status")}
            disabled={disabled("status")}
          />
        </Grid>
        <Grid item container>
          <TextField
            label="Supervision"
            name="supervision"
            value={resident.supervision}
            onChange={handleChange("supervision")}
            disabled={disabled("supervision")}
            fullWidth
          />
        </Grid>
        <Grid item container>
          <TextField
            label="Contact Number"
            name="contactNumber"
            value={resident.contactNumber}
            onChange={handleChange("contactNumber")}
            disabled={disabled("contactNumber")}
            fullWidth
          />
        </Grid>
        <Grid item container>
          <Select
            label="Location"
            name="locationId"
            options={locationOptions}
            value={resident.locationId ? String(resident.locationId) : ""}
            onChange={handleLocationChange}
            disabled={disabled("locationId")}
            addChooseOption
          />
        </Grid>
        <Grid item container>
          <Toggle
            label="Has BPA"
            name="hasBPA"
            value={resident.hasBPA}
            onChange={handleChange("hasBPA")}
            disabled={disabled("hasBPA")}
          />
        </Grid>
        {resident.hasBPA && (
          <Grid item container>
            <Toggle
              label="BPA Pending?"
              name="bpaPending"
              value={resident.bpaPending}
              onChange={handleChange("bpaPending")}
              disabled={disabled("bpaPending")}
            />
          </Grid>
        )}
        {resident.hasBPA && !resident.bpaPending && (
          <Grid item container>
            <DatePicker
              label="BPA End Date"
              inputFormat="MM/dd/yyyy"
              value={
                resident.bpaEndDate
                  ? convertUTCToLocal(resident.bpaEndDate)
                  : null
              }
              onChange={handleDateChange<Resident>(
                "bpaEndDate",
                resident,
                setResident,
                Resident,
                true
              )}
              disabled={disabled("bpaEndDate")}
              renderInput={(params) => <TextField {...params} fullWidth />}
              clearable
              disableCloseOnSelect
            />
          </Grid>
        )}
        <Grid item container>
          <Toggle
            label="UA"
            name="hasUA"
            value={resident.hasUa}
            onChange={handleChange("hasUa")}
            disabled={disabled("hasUa")}
          />
        </Grid>
        {resident.hasUa && (
          <Grid item container>
            <UAPhotos
              residentId={resident.id as number}
              photos={photos}
              newPhotos={newPhotos}
              addPhoto={addPhoto}
              removeNewUAPhoto={removeNewPhoto}
              removePhoto={removePhoto}
              editable={
                !forceDisable &&
                !![REQUEST_MOVE_IN, MOVED_IN, VIEW].find((d) => d === status)
              }
            />
          </Grid>
        )}
        <Grid item container>
          <Toggle
            label="Is GEO"
            name="isGeo"
            value={resident.isGeo}
            onChange={handleChange("isGeo")}
            disabled={disabled("isGeo")}
          />
        </Grid>
        <Grid item container>
          <TextField
            label="Collect"
            name="collect"
            type="number"
            value={resident.totalCollected}
            onChange={handleChange("totalCollected")}
            disabled={disabled("totalCollected")}
            fullWidth
          />
        </Grid>
        <Grid item container>
          <DatePicker
            label="Move In Date"
            inputFormat="MM/dd/yyyy"
            value={
              resident.moveInDate
                ? convertUTCToLocal(resident.moveInDate)
                : null
            }
            onChange={handleDateChange<Resident>(
              "moveInDate",
              resident,
              setResident,
              Resident
            )}
            disabled={disabled("moveInDate")}
            renderInput={(params) => <TextField {...params} fullWidth />}
            clearable
            disableCloseOnSelect
          />
        </Grid>
        <Grid item container>
          <Toggle
            label="Will Contact To Schedule"
            name="contactToSchedule"
            value={resident.contactToSchedule}
            onChange={handleChange("contactToSchedule")}
            disabled={disabled("contactToSchedule")}
          />
        </Grid>
        <Grid item container>
          <DatePicker
            label="Rent Due Date"
            inputFormat="MM/dd/yyyy"
            value={
              resident.rentDueDate
                ? convertUTCToLocal(resident.rentDueDate)
                : null
            }
            onChange={handleDateChange<Resident>(
              "rentDueDate",
              resident,
              setResident,
              Resident,
              true
            )}
            disabled
            renderInput={(params) => <TextField {...params} fullWidth />}
            clearable
            disableCloseOnSelect
          />
        </Grid>
        <Grid item container>
          <Select
            label="Pay Schedule"
            name="paySchedule"
            options={PAYMENT_SCHEDULE_OPTIONS}
            value={resident.paySchedule || ""}
            onChange={handleSelectChange("paySchedule")}
            disabled={disabled("paySchedule")}
            addChooseOption
          />
        </Grid>
        <Grid item container>
          <Toggle
            label="No Payment Contract (NOPC)"
            name="noPaymentContract"
            value={resident.noPaymentContract}
            onChange={handleChange("noPaymentContract")}
            disabled={disabled("noPaymentContract")}
          />
        </Grid>
        <Grid item container>
          <Toggle
            label="Already Been Switched (ABS)"
            name="alreadyBeenSwitched"
            value={resident.alreadyBeenSwitched}
            onChange={handleChange("alreadyBeenSwitched")}
            disabled={disabled("alreadyBeenSwitched")}
          />
        </Grid>
        <Grid item container>
          <Toggle
            label="Cannot Pay Late (CPL)"
            name="cannotPayLate"
            value={resident.cannotPayLate}
            onChange={handleChange("cannotPayLate")}
            disabled={disabled("cannotPayLate")}
          />
        </Grid>
        <Grid item container>
          <Toggle
            label="Improper Payment (IP)"
            name="improperPayment"
            value={resident.improperPayment}
            onChange={handleChange("improperPayment")}
            disabled={disabled("improperPayment")}
          />
        </Grid>
        <Grid item container>
          <TextField
            label="Notes"
            name="notes"
            value={resident.notes}
            onChange={handleChange("notes")}
            disabled={disabled("notes")}
            multiline
            minRows={3}
            fullWidth
          />
        </Grid>
        <Grid item container>
          <TextField
            label="Move Out Notes"
            name="moveOutNotes"
            value={resident.moveOutNotes}
            onChange={handleChange("moveOutNotes")}
            disabled={disabled("moveOutNotes")}
            multiline
            minRows={3}
            fullWidth
          />
        </Grid>
        <Grid item container>
          <DatePicker
            label="Move Out Date"
            inputFormat="MM/dd/yyyy"
            value={
              resident.moveOutDate
                ? convertUTCToLocal(resident.moveOutDate)
                : null
            }
            onChange={handleDateChange<Resident>(
              "moveOutDate",
              resident,
              setResident,
              Resident
            )}
            renderInput={(params) => <TextField {...params} fullWidth />}
            disabled={disabled("moveOutDate")}
            clearable
            disableCloseOnSelect
          />
        </Grid>
      </Grid>
    </form>
  );
}
