import React from "react";
import { Auth } from "aws-amplify";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import LogoutIcon from "@mui/icons-material/Logout";

import { useMe } from "../store/me";

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function SettingsDialog(props: SimpleDialogProps) {
  const { onClose, open } = props;

  const { me } = useMe();

  const logout = () => Auth.signOut();

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Hello {me?.firstName}</DialogTitle>
      <List>
        <ListItem button onClick={logout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Dialog>
  );
}
