import React, { useState, useCallback, useEffect } from "react";
import LocationForm from "../../../components/LocationForm";
// import { editLocation, removeLocation } from "../../../store/locations";
import { useParams, useNavigate } from "react-router-dom";
import { Location } from "../../../classes";
import { useLocationsApi } from "../../../apis";

export default function EditLocation() {
  const { getLocation, updateLocation, deleteLocation } = useLocationsApi();
  const { id } = useParams();
  const navigate = useNavigate();

  const [location, setLocation] = useState<Location>(new Location());

  const fetchData = useCallback(async () => {
    const _location = await getLocation(id);
    setLocation(new Location(_location));
  }, [id, getLocation]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  console.log(location);

  const onSave = async () => {
    try {
      await updateLocation(id, location.prepareForApi());
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const onDelete = async () => {
    try {
      if (window.confirm("Are you sure you want to delete this location?")) {
        await deleteLocation(id);
        navigate(-1);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <LocationForm
      title="Edit Location"
      location={location}
      setLocation={setLocation}
      onSave={onSave}
      onDelete={onDelete}
    />
  );
}
