import DatabaseObject, {
  DatabaseObjectConstructorType,
} from "./database-object";
import { Resident, ResidentConstructorType } from "./resident";
import { Collection, CollectionConstructorType } from "./collection";
import { Location, LocationConstructorType } from "./location";

export interface ActivityConstructorType extends DatabaseObjectConstructorType {
  type: string;
  resident?: ResidentConstructorType;
  collection?: CollectionConstructorType;
  location?: LocationConstructorType;
  residentId?: number;
  collectionId?: number;
  locationId?: number;
  log?: string;
  creatorId?: number;
}
export class Activity extends DatabaseObject {
  type: string = ACTIVITY_TYPES.RESIDENT_CHANGE;
  resident?: Resident;
  collection?: Collection;
  location?: Location;
  residentId?: number;
  collectionId?: number;
  locationId?: number;
  log?: string;
  creatorId?: number;

  constructor(data?: ActivityConstructorType) {
    super(data);

    if (!data) return;

    this.type = data.type;

    if (data.resident) this.resident = new Resident(data.resident);
    if (data.residentId) this.residentId = data.residentId;
    if (data.collection) this.collection = new Collection(data.collection);
    if (data.collectionId) this.collectionId = data.collectionId;
    if (data.location) this.location = new Location(data.location);
    if (data.locationId) this.locationId = data.locationId;
    if (data.log) this.log = data.log;
    if (data.creatorId) this.creatorId = data.creatorId;
  }
}

export const ACTIVITY_TYPES = {
  RESIDENT_CHANGE: "RESIDENT_CHANGE",
  PENDING: "PENDING",
  REQUEST_MOVE_IN: "REQUEST_MOVE_IN",
  MOVED_IN: "MOVED_IN",
  REQUEST_MOVE_OUT: "REQUEST_MOVE_OUT",
  MOVED_OUT: "MOVED_OUT",
  LOCATION_CHANGE: "LOCATION_CHANGE",
  PAYMENT_SUBMITTED: "PAYMENT_SUBMITTED",
  PAYMENT_REJECTED: "PAYMENT_REJECTED",
  PAYMENT_APPROVED: "PAYMENT_APPROVED",
};
