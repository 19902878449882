import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { DashboardRoutes } from "./Dashboard";
import { Login } from "./Login";

import { RequireAuth } from "../components/RequireAuth";
import { MeProvider } from "../store/me";

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route
          path="/dashboard/*"
          element={
            <RequireAuth>
              <MeProvider>
                <DashboardRoutes />
              </MeProvider>
            </RequireAuth>
          }
        />

        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}
