import React from "react";
import styled, { css } from "styled-components";
import {
  TableRow,
  TableBody,
  TableHeader,
  TableSelectedSectionButton,
  TableCell,
  TableSearchField,
  Typography,
  LocationFilter,
  TableFilterModal,
} from "./index";

import { useNavigate } from "react-router-dom";
import format from "date-fns/format";
import { colors } from "../constants";
import { convertUTCToLocal } from "../utils/date-helpers";
import {
  faEye,
  faDollarSign,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Collection, CollectionItem, CollectionPayment } from "../classes";
import { Resident } from "../classes/resident";
import { Location } from "../classes/location";
import { COLLECTION_ITEM_TYPES, OTHER_CODES } from "../classes/collection-item";
import { COLLECTION_PAYMENT_TYPES } from "../classes/collection-payment";
import { formatMoney } from "../utils";
import { useCollectionsApi } from "../apis";

const Header = () => (
  <>
    <TableCell basis="150px">Name</TableCell>
    <TableCell basis="66px">Location</TableCell>
    <TableCell basis="76px">Pay Sched</TableCell>
    <TableCell basis="86px">BPA End</TableCell>
    <TableCell basis="86px">Rent Due</TableCell>
    <TableCell basis="68px">Balance</TableCell>
    <TableCell basis="140px">Flags</TableCell>
    <TableCell basis="200px">Payment Contracts</TableCell>
  </>
);

const EmptyView = () => (
  <Typography
    type="h4"
    bold
    align="center"
    spacing="mt-8"
    color={colors.grey[5]}
  >
    No Residents
  </Typography>
);

interface RecordProps {
  resident: Resident;
  location?: Location;
}

interface RowProps {
  row: {
    resident?: Resident;
    location?: Location;
  };
}
const Row = ({ row, ...props }: RowProps) => {
  return (
    <TableRow row={row} SelectedSectionElem={SelectedSection} {...props}>
      <TableCell basis="150px" semiBold>
        {row.resident && `${row.resident.lastName}, ${row.resident.firstName}`}
      </TableCell>
      <TableCell basis="66px" color={colors.grey[3]}>
        {row.location?.name}
      </TableCell>
      <TableCell basis="76px" color={colors.grey[3]}>
        {row.resident?.paySchedule}
      </TableCell>
      <TableCell basis="86px" color={colors.grey[3]}>
        {row.resident?.hasBPA && row.resident?.bpaEndDate
          ? format(new Date(row.resident.bpaEndDate), "P")
          : ""}
      </TableCell>
      <TableCell basis="86px" color={colors.grey[3]}>
        {row.resident && row.resident.rentDueDate
          ? format(convertUTCToLocal(row.resident.rentDueDate), "M/dd/yyyy")
          : ""}
      </TableCell>
      <TableCell basis="68px" color={colors.grey[3]}>
        {row.resident && formatMoney(row.resident.overallBalance)}
      </TableCell>
      <TableCell basis="140px" color={colors.grey[3]}>
        {[
          row.resident && row.resident.noPaymentContract ? "NOPC" : null,
          row.resident && row.resident.alreadyBeenSwitched ? "ABS" : null,
          row.resident && row.resident.cannotPayLate ? "CPL" : null,
          row.resident && row.resident.improperPayment ? "IP" : null,
        ]
          .filter((a) => a)
          .join(", ")}
      </TableCell>
      <TableCell basis="200px" color={colors.grey[3]}>
        {row.resident?.paymentContract}
      </TableCell>
    </TableRow>
  );
};

interface SelectedSectionProps {
  row: RecordProps;
  onAddCollection: (resident: Resident, collection: Collection) => void;
  onPaymentContract: (resident: Resident) => void;
}
const SelectedSection = ({
  row,
  onAddCollection,
  onPaymentContract,
}: SelectedSectionProps) => {
  const { getCollections } = useCollectionsApi();
  const navigate = useNavigate();

  const goTo = (path: string) => () =>
    navigate(`/dashboard/residents/${path}/${row.resident.id}`);

  const addCollection = async () => {
    const _collections = await getCollections({
      residentId: row.resident.id,
      take: 0,
    });
    const overallBalance = _collections.overallBalance;

    const collectionData: {
      residentId: number;
      items: CollectionItem[];
      payments: CollectionPayment[];
    } = {
      residentId: row.resident.id as number,
      items: [],
      payments: [],
    };

    if (overallBalance > 0) {
      collectionData.payments.push(
        new CollectionPayment({
          type: COLLECTION_PAYMENT_TYPES.CREDIT,
          amount: overallBalance,
        })
      );
    }

    if (overallBalance < 0) {
      collectionData.items.push(
        new CollectionItem({
          text: "Existing Debt",
          price: Math.abs(overallBalance),
          code: OTHER_CODES.EXISTING_DEBT,
          type: COLLECTION_ITEM_TYPES.DEBT,
        })
      );
    } else {
      // Push an empty item, because we'll
      // expect the user to add one anyways.
      collectionData.items.push(new CollectionItem());
    }

    onAddCollection(row.resident, new Collection(collectionData));
  };

  const addPaymentContract = () => {
    onPaymentContract(row.resident);
  };

  return (
    <>
      <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
        View
      </TableSelectedSectionButton>

      <TableSelectedSectionButton icon={faDollarSign} onClick={addCollection}>
        Collect
      </TableSelectedSectionButton>

      <TableSelectedSectionButton
        icon={faPencilAlt}
        onClick={addPaymentContract}
      >
        Payment Contract
      </TableSelectedSectionButton>
    </>
  );
};

const RentDueTableStyles = styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`
  ${({ hasDateFinder }) =>
    hasDateFinder
      ? // 48px - Tabs
        // 48px - Date selector
        // 52px - Quick search
        // 67px - Table header
        // 62px - Footer tabs
        // +35px - Just cause
        css`
          height: calc(100% - 48px - 48px - 52px - 67px + 35px);
        `
      : css`
          height: calc(100% - 48px - 52px - 67px + 35px);
        `}
`;

interface RentDueTableProps {
  rows: RecordProps[];
  total: number;
  page: number;
  take: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  hasDateFinder?: boolean;
  onAddCollection: (resident: Resident, collection: Collection) => void;
  onPaymentContract: (resident: Resident) => void;
}

const RentDueTable = ({
  rows,
  search,
  setSearch,
  hasDateFinder,
  total,
  page,
  take,
  setPage,
  onAddCollection,
  onPaymentContract,
}: RentDueTableProps) => {
  const filterContent = [LocationFilter];

  return (
    <RentDueTableStyles hasDateFinder={hasDateFinder}>
      <TableFilterModal filterContent={filterContent} />
      <TableSearchField
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        filterContent={filterContent}
      />
      <TableHeader>
        <Header />
      </TableHeader>
      <TableBody
        rows={rows}
        total={total}
        page={page}
        take={take}
        setPage={setPage}
        RowElem={Row}
        EmptyViewElem={EmptyView}
        onAddCollection={onAddCollection}
        onPaymentContract={onPaymentContract}
      />
    </RentDueTableStyles>
  );
};

export default RentDueTable;
